import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './datasheet.css';
import './datatable.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import {createTheme, MantineProvider} from "@mantine/core";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {App} from "./App";
import {Login} from "./pages/public/Login";
import {RequireAuth} from "./auth/RequireAuth";
import {Unauthorized} from "./pages/public/Unauthorized";
import {AuthProvider} from "./context/AuthProvider";
import {UserSettings} from "./pages/private/UserSettings";
import {UserHome} from "./pages/private/UserHome";
import {OrgDashboard} from "./pages/private/organisation/OrgDashboard";
import {OrgSettings} from "./pages/private/organisation/OrgSettings";
import {NotFound} from "./pages/public/NotFound";
import {Notifications} from "@mantine/notifications";
import {TaxList} from "./pages/private/organisation/taxes/TaxList";
import {ItemList} from './pages/private/organisation/items/ItemList';
import {ContactList} from "./pages/private/organisation/contacts/ContactList";
import {IncomingInvoicesList} from "./pages/private/organisation/incoming/invoices/IncomingInvoicesList";
import {FileList} from "./pages/private/organisation/files/FileList";
import {RegisterReportList} from "./pages/private/organisation/outgoing/registerReports/RegisterReportList";
import {BalanceList} from "./pages/private/organisation/balances/BalanceList";
import {TransactionList} from "./pages/private/organisation/transactions/TransactionList";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  fontFamily: 'Verdana, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: {fontFamily: 'Verdana, sans-serif'},
});

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <Notifications limit={5} autoClose={1500} position="top-center"/>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App/>}>
              <Route index element={<Login/>}/>
              <Route path="login" element={<Login/>}/>
              <Route path="unauthorized" element={<Unauthorized/>}/>
              <Route path="*" element={<NotFound/>}/>
              <Route path="app" element={<RequireAuth/>}>
                <Route index element={<UserHome/>}/>
                <Route path="settings" element={<UserSettings/>}/>
                <Route path="organisation/:organisationId">
                  <Route index element={<OrgDashboard/>}/>
                  <Route path="documents">
                    <Route path="incoming-invoices" element={<IncomingInvoicesList/>}/>
                    <Route path="register-reports" element={<RegisterReportList/>}/>
                  </Route>
                  <Route path="ledger">
                    <Route path="balances" element={<BalanceList/>}/>
                    <Route path="transactions" element={<TransactionList/>}/>
                  </Route>
                  <Route path="items" element={<ItemList/>}/>
                  <Route path="contacts" element={<ContactList/>}/>
                  <Route path="files" element={<FileList/>}/>
                  <Route path="settings">
                    <Route path="organisation" element={<OrgSettings/>}/>
                    <Route path="taxes" element={<TaxList/>}/>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </MantineProvider>
  </React.StrictMode>
);

import {Button, Checkbox, Flex, Grid, Modal, Space, Switch, Tabs, Text} from "@mantine/core";
import React, {FormEvent, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {
  ApiBomItem,
  ApiClient,
  ApiItem, ApiItemCategory,
  ApiUnit,
  UpsertBomItemRequest,
  UpsertItemRequest
} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX, IconExclamationCircle} from "@tabler/icons-react";
import {UnitSelect} from "../../../../common/select/UnitSelect";
import {createIdMap} from "../../../../utils/objectUtils";
import {TextInput} from "../../../../common/TextInput";
import {DataSheet} from "../../../../common/datasheet/DataSheet";
import {Option} from "../../../../common/datasheet/DataSheetSelect";
import {CategorySelect} from "../../../../common/select/CategorySelect";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";

interface Props {
  onSuccess?: (apiItem: ApiItem) => void
  onClose?: () => void
}

export interface Item {
  id?: string
  name: string
  categoryId: string | null
  unitId: string | null
  sold: boolean
  stocked: boolean
  produced: boolean
  active: boolean
  bomItems: BomItem[]

  [key: string]: any
}

export interface BomItem {
  id: string
  partId: string
  quantity: number

  [key: string]: any
}

const mapApiItem = (apiItem?: ApiItem) => {
  return {
    id: apiItem?.id,
    name: apiItem ? apiItem.name : '',
    categoryId: apiItem ? apiItem.categoryId : '',
    unitId: apiItem ? apiItem.unitId : '',
    sold: apiItem ? apiItem.sold : true,
    stocked: apiItem ? apiItem.stocked : true,
    produced: apiItem ? apiItem.produced : true,
    active: apiItem ? apiItem.active : true,
    bomItems: apiItem
      ? apiItem.bomItems.map(mapApiBomItem)
      : [...Array(3)].map(_ => mapApiBomItem(undefined))
  } as Item;
}

const mapApiBomItem = (apiBomItem?: ApiBomItem) => {
  return {
    id: apiBomItem?.id ?? crypto.randomUUID(),
    partId: apiBomItem?.partId,
    quantity: apiBomItem?.quantity
  } as BomItem
}

export const ItemModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [item, setItem] = useState<Item>(mapApiItem(undefined));
  const [categories, setCategories] = useState<ApiItemCategory[]>([]);
  const [units, setUnits] = useState<ApiUnit[]>([]);
  const [unitsById, setUnitsById] = useState<Record<string, ApiUnit>>({});
  const [itemsById, setItemsById] = useState<Record<string, ApiItem>>({});
  const [itemOptions, setItemOptions] = useState<Option[]>([]);

  const itemModalRef = useRef<any>();

  const fetchEntities = useCallback(() => {
    if (organisationId && opened) {
      setLoading(true);
      Promise.all([
        ApiClient.getUnits(organisationId).then(resp => resp.data),
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getItemCategories(organisationId).then(resp => resp.data)
      ])
        .then(([units, items, categories]) => {
          setUnits(units);
          setUnitsById(createIdMap(units));
          setItemsById(createIdMap(items));
          setItemOptions(items.map(i => ({value: i.id, label: i.name} as Option)));
          setCategories(categories);
        }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, opened]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const openModal = useCallback((apiItem?: ApiItem) => {
    setItem(mapApiItem(apiItem));
    open()
  }, [open]);

  const closeModal = useCallback(() => {
    setItem(mapApiItem(undefined))
    onClose?.();
    close();
  }, [setItem, close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    const request = {
      name: item.name,
      categoryId: item.categoryId,
      unitId: item.unitId,
      sold: item.sold,
      stocked: item.stocked,
      produced: item.produced,
      active: item.active,
      bomItems: item.bomItems.map(bi => ({partId: bi.partId, quantity: bi.quantity} as UpsertBomItemRequest))
    } as UpsertItemRequest;
    (item.id
        ? ApiClient.updateItem(organisationId ?? '', item.id, request)
        : ApiClient.createItem(organisationId ?? '', request)
    )
      .then((resp) => {
        notifySavedChanges();
        onSuccess?.(resp.data);
        closeModal();
      })
      .catch(err => {
        notifyError(err);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  const addEmptyBomItems = () => {
    setItem({...item, bomItems: [{} as BomItem, {} as BomItem, {} as BomItem]});
  }

  const gridColSpan = [1.5, 8, 2.5];

  return <>
    <Modal opened={opened}
           id="item_modal"
           onClose={closeModal}
           title="Item"
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size={"600px"}
    >
      <Flex align="center" direction="column" gap="sm">
        <form style={{width: "100%"}} onSubmit={handleSave}>
          <Grid style={{width: '35rem'}} gutter="sm" align="center">

            <Grid.Col span={gridColSpan[0]}>
              <Text size="sm">Name: </Text>
            </Grid.Col>
            <Grid.Col span={gridColSpan[1]}>
              <TextInput value={item.name}
                         disabled={loading}
                         onChange={value => setItem({...item, name: value ?? ''})}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[2]}>
              <Checkbox label="Stocked" checked={item.stocked}
                        onChange={e => setItem({...item, stocked: (e.target.checked)})}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[0]}>
              <Text size="sm">Unit: </Text>
            </Grid.Col>
            <Grid.Col span={gridColSpan[1]}>
              <UnitSelect unitId={item.unitId ?? undefined}
                          items={units}
                          onChange={unitId => setItem({...item, unitId: unitId ?? null})}
                          onNewCreated={newUnit => {
                            setItem({...item, unitId: newUnit.id});
                            fetchEntities();
                          }}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[2]}>
              <Checkbox label="Sold" checked={item.sold} onChange={e => setItem({...item, sold: (e.target.checked)})}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[0]}>
              <Text size="sm">Category: </Text>
            </Grid.Col>
            <Grid.Col span={gridColSpan[1]}>
              <CategorySelect categoryId={item.categoryId ?? undefined}
                              categories={categories}
                              onChange={categoryId => setItem({...item, categoryId: categoryId ?? null})}
                              onNewCreated={newCategory => {
                                setItem({...item, categoryId: newCategory.id});
                                fetchEntities();
                              }}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[2]}>
              <Checkbox label="Produced" checked={item.produced}
                        onChange={e => setItem({...item, produced: (e.target.checked)})}/>
            </Grid.Col>

          </Grid>

          <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
            <Switch size={"sm"}
                    labelPosition="left"
                    label="Active"
                    style={{paddingTop: '5px'}}
                    checked={item.active}
                    onChange={e => setItem({...item, active: e.target.checked})}
            />
          </Flex>

          <Tabs defaultValue="bom" variant={"outline"} style={{marginTop: '10px'}}>
            <Tabs.List grow>
              <Tabs.Tab value="bom">
                Bill Of Materials
              </Tabs.Tab>
              <Tabs.Tab value="usedIn">
                Used in
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="bom">
              <Flex justify="center">
                {item.bomItems.length == 0
                  ? <Button size="xs" style={{marginTop: 20}} onClick={addEmptyBomItems}>Add bom items</Button>
                  : <DataSheet
                    showLineNumber={true}
                    noWrap={true}
                    columns={[
                      {
                        name: 'partId',
                        displayName: 'Part (item)',
                        type: 'SELECT',
                        align: 'left',
                        minWidthPx: 350,
                        selectOptions: itemOptions,
                        modalRef:itemModalRef,
                      },
                      {
                        name: 'unit', displayName: 'Unit', type: 'DERIVED', align: 'center',
                        deriveFunction: (row) => {
                          const part = itemsById[item.bomItems[row]?.partId ?? ''];
                          const unit = unitsById[part?.unitId ?? ''];
                          return unit?.shortName ?? '';
                        }
                      },
                      {
                        name: 'quantity',
                        displayName: 'Quantity',
                        type: 'NUMBER',
                        align: 'right',
                      },
                    ]}
                    rows={item.bomItems}
                    updateRows={(rows) => {
                      const newBomItems = rows.map(row => {
                        const bomItem = {} as BomItem;
                        Object.keys(row).forEach(col => bomItem[col] = row[col])
                        return bomItem;
                      });
                      setItem({...item, bomItems: newBomItems});
                    }}
                    rowValidityCheckers={[]}
                  />}
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="usedIn">
              Coming soon...
            </Tabs.Panel>
          </Tabs>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>} loading={loading}>
              Save
            </Button>
            <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
    {/*<ItemModal ref={itemModalRef} onSuccess={fetchEntities} />*/}
  </>;
});
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ApiClient, ApiExtraction, ApiFile} from "../../../../utils/http/apiClient";
import {IconFileStack, IconTextScan2} from "@tabler/icons-react";
import {Box, LoadingOverlay, Tabs} from "@mantine/core";
import {FileModal} from "./FileModal";
import {FilesTab} from "./FilesTab";
import {FileExtractionsTab} from "./FileExtractionsTab";
import {ExtractionModal} from "./extraction/ExtractionModal";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";

export const FileList = () => {
  const organisationId = useOrganisationId();
  const [files, setFiles] = useState<ApiFile[]>([]);
  const [extractions, setExtractions] = useState<ApiExtraction[]>([]);
  const [loading, setLoading] = useState(false);

  const fileUploadModalRef = useRef<any>();
  const extractionModalRef = useRef<any>();

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);

      ApiClient.getFiles(organisationId)
        .then(resp => setFiles(resp.data))
        .catch(notifyError)
        .finally(() => setLoading(false));
    }

    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getFiles(organisationId),
        ApiClient.getExtractions(organisationId),
      ])
        .then(([files, extractions]) => {
          setFiles(files.data);
          setExtractions(extractions.data)
        })
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const handleFileUpload = (files: FileList | null) => {
    if (!files || files.length !== 1) {
      return;
    }
    const file = files.item(0);
    if (organisationId && file) {
      setLoading(true);
      ApiClient.uploadFile(organisationId, file)
        .then(resp => fileUploadModalRef.current?.openModal(resp.data.id))
        .then(fetchEntities)
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  }

  const createInvoiceFromExtraction = (extractionId: string) => {
    if (organisationId) {
      setLoading(true);
      ApiClient.createInvoiceFromExtraction(organisationId, extractionId)
        .then(fetchEntities)
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false))
    }
  }

  const unlinkExtractionDocument = (extractionId: string) => {
    if (organisationId) {
      setLoading(true);
      ApiClient.unlinkExtractionDocument(organisationId, extractionId)
        .then(fetchEntities)
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false))
    }
  }

  return <>
    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>

      <Tabs variant="outline" defaultValue="files">
        <Tabs.List>
          <Tabs.Tab value="files" leftSection={<IconFileStack stroke={1.5}/>}>
            Files
          </Tabs.Tab>
          <Tabs.Tab value="extractions" leftSection={<IconTextScan2 stroke={1.5}/>}>
            Extractions
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="files">
          <FilesTab files={files} handleFileUpload={handleFileUpload} fileUploadModalRef={fileUploadModalRef}/>
        </Tabs.Panel>

        <Tabs.Panel value="extractions">
          <FileExtractionsTab extractions={extractions}
                              extractionModalRef={extractionModalRef}
                              createInvoiceFromExtraction={createInvoiceFromExtraction}
                              unlinkExtractionDocument={unlinkExtractionDocument}/>
        </Tabs.Panel>

      </Tabs>
    </Box>

    <FileModal ref={fileUploadModalRef} onSuccess={fetchEntities}/>
    <ExtractionModal ref={extractionModalRef} onSuccess={fetchEntities}/>
  </>;
}

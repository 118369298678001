import {Table} from "@mantine/core";
import React, {MouseEvent, useCallback, useEffect, useRef, useState} from "react";
import {Property} from 'csstype'
import {DataSheetContextMenu} from "./DataSheetContextMenu";
import {GroupBase, OptionsOrGroups, SingleValue} from "react-select";
import {DataSheetSelect} from "./DataSheetSelect";
import {DataSheetPercentInput} from "./DataSheetPercentInput";
import {DataSheetNumberInput} from "./DataSheetNumberInput";
import {DataSheetTextInput} from "./DataSheetTextInput";
import {formatAmount, formatNumber, formatPercentage} from "../../utils/formatUtils";
import {DataSheetAmountInput} from "./DataSheetAmountInput";
import {roundToX} from "../../utils/objectUtils";
import {cleanNumber} from "../../pages/private/organisation/files/extraction/extractionsUtils";

export type CellType = 'TEXT' | 'PERCENT' | 'AMOUNT' | 'NUMBER' | 'SELECT' | 'DERIVED';

export type ChangeCause = 'Enter' | 'Tab' | 'Escape' | 'ArrowRight' | 'ArrowUp' | 'ArrowDown' | 'Unknown';

export function mapChangeCause(value: any): ChangeCause {
  switch (value) {
    case 'Enter':
    case 'Tab':
    case 'Escape':
    case 'ArrowRight':
    case 'ArrowUp':
    case 'ArrowDown':
      return value;
    default:
      return 'Unknown';
  }
}

const delta = {
  'Enter': {x: 0, y: 0},
  'Tab': {x: 1, y: 0},
  'Escape': {x: 0, y: 0},
  'ArrowRight': {x: 1, y: 0},
  'ArrowUp': {x: 0, y: -1},
  'ArrowDown': {x: 0, y: 1},
} as Record<ChangeCause, { x: number, y: number }>;

export interface Column {
  name: string
  displayName: string
  type: CellType
  align: Property.TextAlign | undefined
  validityChecker?: (text: string) => boolean
  selectOptions?: OptionsOrGroups<any, GroupBase<any>>
  deriveFunction?: (row: number) => string
  readOnly?: boolean,
  modalRef?: React.MutableRefObject<any>
  minWidthPx?: number
}

type Row = { [name: string]: string };

interface Props {
  caption?: string
  columns: Column[],
  rows: { [name: string]: string }[]
  updateRows: (rows: { [name: string]: string }[]) => void
  rowValidityCheckers: ((cells: { [name: string]: string }) => boolean)[]
  showLineNumber?: boolean
  noWrap?: boolean
}

interface Cell {
  row: number,
  col: string
}

export const DataSheet = ({
                            caption,
                            columns,
                            rows,
                            updateRows,
                            rowValidityCheckers,
                            showLineNumber,
                            noWrap
                          }: Props) => {

  const [selectedCells, setSelectedCells] = useState<Cell[]>([]);
  const [editingCell, setEditingCell] = useState<Cell | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setFocused] = useState(false);
  const tableRef = useRef<any>();
  const contextMenuRef = useRef<any>();
  const [columnWidths, setColumnsWidths] = useState<number[]>([]);
  const [selectionStartCell, setSelectionStartCell] = useState<Cell | null>(null);
  const [selectionEndCell, setSelectionEndCell] = useState<Cell | null>(null);
  const [mouseDragging, setMouseDragging] = useState(false);

  const [columnMap, setColumnMap] = useState<Record<string, number>>({});

  const calculateColumnWidths = (columns: Column[], rows: { [p: string]: string }[]) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "14px Verdana, sans-serif";
    }
    return columns.map(col => {
      const headerWords = noWrap
        ? [col.displayName + "     "]
        : col.displayName.split(" ").map(word => word + "   ");
      const cells = rows
        .map(r => r[col.name] ?? '')
        .map((_, rowIdx) => renderTextCell(rowIdx, col.name));
      const minWordWidth = [...cells, ...headerWords]
        .map(cell => (context?.measureText(cell ?? '').width ?? 0) + 2)
        .reduce((acc, curr) => Math.max(acc, curr), 0);
      return Math.max(minWordWidth, col.minWidthPx ?? 0);
    });
  }

  useEffect(() => {
    const columnMap = {} as Record<string, number>;
    columns.forEach((c, idx) => columnMap[c.name] = idx);
    setColumnMap(columnMap);
    setColumnsWidths(calculateColumnWidths(columns, rows))
  }, [columns, rows]);


  useEffect(() => {
    if (selectionStartCell && selectionEndCell) {
      const col1 = Math.min(
        columnMap[selectionStartCell.col],
        columnMap[selectionEndCell?.col]);
      const col2 = Math.max(
        columnMap[selectionStartCell.col],
        columnMap[selectionEndCell?.col]);
      const row1 = Math.min(selectionStartCell.row, selectionEndCell.row);
      const row2 = Math.max(selectionStartCell.row, selectionEndCell.row);

      const newSelectedCells = [] as Cell[];
      for (let row = row1; row <= row2; row++) {
        for (let col = col1; col <= col2; col++) {
          newSelectedCells.push({row, col: columns[col].name});
        }
      }
      setSelectedCells(newSelectedCells);
    }
  }, [selectionStartCell, selectionEndCell]);

  const windowMouseDownHandler = useCallback((event: Event) => {
    const clickedOutside = !tableRef.current?.contains(event.target);
    if (clickedOutside) {
      setFocused(false);
      setSelectionCell(null);
      setEditingCell(null);
    } else {
      setFocused(true);
    }
  }, [tableRef, isFocused]);

  useEffect(() => {
    window.addEventListener('mousedown', windowMouseDownHandler, false);
    return () => {
      window.removeEventListener('mousedown', windowMouseDownHandler, false);
    }
  }, [tableRef, windowMouseDownHandler]);

  const focusTable = useCallback(() => {
    if (!isFocused) {
      tableRef.current?.focus({preventScroll: true});
      setFocused(true);
      if (selectedCells.length === 0) {
        setSelectionCell({row: 0, col: columns[0].name});
      }
    }
    window.scroll(0, 0);
  }, [tableRef, isFocused]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!isFocused || editingCell) {
      return;
    }

    if (event.key === 'v' && event.ctrlKey) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.key === 'Enter' || (event.key.length === 1 && !event.ctrlKey)) {
      if (columns[columnMap[selectionEndCell?.col ?? '']].type !== 'DERIVED') {
        setSelectionCell(null);
        setEditingCell(selectionEndCell);
        setInputValue(event.key.length === 1 // All printable characters have length == 1
          ? event.key
          : rows[selectionEndCell?.row ?? 0][selectionEndCell?.col ?? '']);
      }

    } else if (event.key === 'Tab' && !event.shiftKey) {

      const colIdx = columnMap[selectedCells[0].col];
      const newCol = colIdx == columns.length - 1 ? 0 : colIdx + 1;
      const newRow = colIdx == columns.length - 1
        ? Math.min(selectedCells[0].row + 1, rows.length - 1)
        : selectedCells[0].row;
      setSelectionCell({row: newRow, col: columns[newCol].name});

    } else if (event.key === 'Tab' && event.shiftKey) {

      const colIdx = columnMap[selectedCells[0].col];
      const newCol = colIdx == 0 ? columns.length - 1 : colIdx - 1;
      const newRow = colIdx == 0
        ? Math.max(selectedCells[0].row - 1, 0)
        : selectedCells[0].row;
      setSelectionCell({row: newRow, col: columns[newCol].name});

    } else if (event.key === 'ArrowLeft' && selectionEndCell) {

      const nextCell = {
        row: selectionEndCell.row,
        col: columns[Math.max(columnMap[selectionEndCell.col] - 1, 0)].name
      };
      setSelectionStartCell(event.shiftKey ? selectionStartCell : nextCell);
      setSelectionEndCell(nextCell);

    } else if (selectionEndCell && (event.key === 'ArrowRight' || event.key === 'Tab')) {

      const nextCell = {
        row: selectionEndCell.row,
        col: columns[Math.min(columnMap[selectionEndCell.col] + 1, columns.length - 1)].name
      };
      setSelectionStartCell(event.shiftKey ? selectionStartCell : nextCell);
      setSelectionEndCell(nextCell);

    } else if (selectionEndCell && event.key === 'ArrowUp') {

      const nextCell = {
        row: Math.max(selectionEndCell.row - 1, 0),
        col: selectionEndCell.col
      };
      setSelectionStartCell(event.shiftKey ? selectionStartCell : nextCell);
      setSelectionEndCell(nextCell);

    } else if (selectionEndCell && event.key === 'ArrowDown') {

      const nextCell = {
        row: Math.min(selectionEndCell.row + 1, rows.length - 1),
        col: selectionEndCell.col
      };
      setSelectionStartCell(event.shiftKey ? selectionStartCell : nextCell);
      setSelectionEndCell(nextCell);


    } else if (event.key === 'Delete' || event.key === 'Backspace') {

      updateCells(selectedCells, undefined);

    } else if (event.key === 'c' && event.ctrlKey) {

      handleCopy();

    } else if (event.key == 'x' && event.ctrlKey) {

      handleCut();

    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(selectedCells.map(c => rows[c.row][c.col]).join('\n'))
      .then(() => null);
  }
  const handleCopyValue = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => null);
  }

  const handleCut = () => {
    navigator.clipboard
      .writeText(selectedCells.map(c => rows[c.row][c.col]).join('\n'))
      .then(() => {
        const newRows = [...rows];
        selectedCells.forEach((_, idx) => {
          const row = (selectionStartCell?.row ?? 0) + idx;
          const col = selectionStartCell?.col ?? '';
          newRows[row] = {...newRows[row], [col]: ''};
        })
        updateRows(newRows);
      });
  }

  const handlePaste = (event: Event) => {
    if (isFocused) {
      const clipboard = (event as ClipboardEvent);
      const text = clipboard.clipboardData?.getData("text") ?? '';
      const lines = text.split('\n');

      const newRows = [...rows];
      const rowOffset = selectionStartCell?.row ?? 0;
      const colOffset = columnMap[selectionStartCell?.col ?? ''];

      const requiredLength = lines.length + rowOffset;

      while (newRows.length < requiredLength) {
        newRows.push({});
      }

      lines.forEach((line, rowIdx) => {
        newRows[rowIdx] = {...newRows[rowIdx]};
        const cols = line.split('|');
        cols.forEach((value, colIdx) => {
          const row = rowOffset + rowIdx;
          const col = columns[colOffset + colIdx].name;
          newRows[row][col] = value;
        })
      })
      updateRows(newRows);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, false);
    window.addEventListener('paste', handlePaste, false);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
      window.removeEventListener('paste', handlePaste, false);
    }
  }, [handleKeyDown, handlePaste, selectionStartCell, selectionEndCell]);

  const cellsNotEqual = useCallback((c1: Cell | null, c2: Cell | null) => {
    if (c1 === null && c2 === null) {
      return true;
    }
    return c1?.row !== c2?.row || c1?.col !== c2?.col;
  }, []);

  const setSelectionCell = (cell: Cell | null) => {
    if (cellsNotEqual(cell, selectionStartCell) || cellsNotEqual(cell, selectionEndCell)) {
      setSelectionStartCell(cell);
      setSelectionEndCell(cell);
    }
  }

  const handleCellDoubleClick = (event: MouseEvent<HTMLTableCellElement>, row: number, col: string) => {
    event.preventDefault();
    if (columns[columnMap[col]].type !== 'DERIVED') {
      setSelectionCell({row, col});
      setEditingCell({row, col});
      setInputValue(rows[row][col]);
    }
    focusTable();
  }

  const getSelectedCellsSum = () => {
    const sum = selectedCells
      .map(c => Number(cleanNumber(rows[c.row][c.col])))
      .reduce((acc, curr) => acc + curr, 0);
    return roundToX(sum, 4);
  }

  const handleContextMenu = (event: MouseEvent<HTMLTableCellElement>, row: number, col: string) => {
    event.preventDefault();
    contextMenuRef.current.openMenu({
      row,
      col,
      cellType: columns[columnMap[col]].type,
      left: event.clientX,
      top: event.clientY,
      selectedSum: getSelectedCellsSum()
    });
  }

  const handleCellMouseDown = (event: MouseEvent<HTMLTableCellElement>, row: number, col: string) => {
    focusTable();

    if (event.button !== 0 || editingCell) {
      return; // Ignore right click and other clicks while editing a cell
    }

    if (selectionEndCell?.row !== row || selectionEndCell?.col !== col || !mouseDragging) {
      if (event.shiftKey) {
        setSelectionEndCell({row, col});
      } else {
        setSelectionCell({row, col});
        setMouseDragging(true);
      }
    }
  }

  const handleCellMouseMove = (event: MouseEvent<HTMLTableCellElement>, row: number, col: string) => {
    if (mouseDragging) {
      if (selectionEndCell?.row !== row || selectionEndCell?.col !== col) {
        setSelectionEndCell({row, col});
      }
    }
  }

  const handleCellMouseUp = (event: MouseEvent<HTMLTableCellElement>, row: number, col: string) => {
    setMouseDragging(false);
  }

  const handleSelectOnChange = (option: SingleValue<{
    label: string,
    value: string
  }>, row: number, colName: string) => {
    setSelectionCell({row, col: colName});
    setEditingCell(null);
    updateCell(row, colName, option?.value ?? '');
    focusTable();
  }

  const handleCellChange = (row: number, col: string, value: any, cause: ChangeCause) => {
    setEditingCell(null);
    setSelectionCell(getNextCell(row, col, cause));
    if (cause !== 'Escape') {
      updateCell(row, col, value);
    }
    focusTable();
  }

  const updateCell = useCallback((row: number, colName: string, value: string) => {
    const newRows = [...rows];
    newRows[row] = {...rows[row], [colName]: value};
    updateRows(newRows);
  }, [updateRows]);

  const updateCells = useCallback((cells: Cell[], value: any) => {
    const newRows = [...rows];
    cells.forEach(c => {
      newRows[c.row] = {...newRows[c.row], [c.col]: value}
    });
    updateRows(newRows);
  }, [updateRows]);

  const getNextCell = (row: number, colName: string, cause: ChangeCause) => {
    const col = columnMap[colName];
    const dx = delta[cause].x;
    const dy = delta[cause].y;
    const nextCol = Math.max(Math.min(col + dx, columns.length - 1), 0);
    const nextRow = Math.max(Math.min(row + dy, rows.length - 1), 0);
    return {row: nextRow, col: columns[nextCol].name} as Cell;
  }

  const isCellSelected = useCallback((row: number, col: string) => {
    return isFocused && selectedCells.filter(cell => cell.row === row && cell.col === col).length > 0;
  }, [isFocused, selectedCells]);

  const isCellEdited = useCallback((row: number, col: string) => {
    return editingCell?.row === row && editingCell?.col === col;
  }, [editingCell]);

  const getCellClassNames = useCallback((row: number, col: string, valid: boolean) => {
    const selectedClass = isCellSelected(row, col) ? 'cellSelected' : '';
    const validClass = !isCellSelected(row, col) && !valid ? 'invalidValue' : '';
    const readOnlyClass = columns[columnMap[col]]?.readOnly || columns[columnMap[col]]?.type === 'DERIVED'
      ? 'readOnly'
      : '';
    return selectedClass + ' ' + validClass + ' ' + readOnlyClass;
  }, [isCellSelected, columnMap]);

  const isValidRow = (row: number) => {
    return rowValidityCheckers
      .map(checker => checker(rows[row]))
      .filter(valid => !valid).length === 0;
  }

  const renderInputCell = (row: number, col: string) => {
    if (columns[columnMap[col]].type == 'NUMBER') {
      return <DataSheetNumberInput value={Number.isNaN(Number(inputValue)) ? null : Number(inputValue)}
                                   onChange={(value, cause) => handleCellChange(row, col, value, cause)}
                                   defaultValue={Number(inputValue)}/>
    } else if (columns[columnMap[col]].type == 'AMOUNT') {
      return <DataSheetAmountInput value={Number.isNaN(Number(inputValue)) ? null : Number(inputValue)}
                                   onChange={(value, cause) => handleCellChange(row, col, value, cause)}
                                   defaultValue={Number(inputValue)}/>
    } else if (columns[columnMap[col]].type == 'PERCENT') {
      return <DataSheetPercentInput value={Number(inputValue)}
                                    onChange={(value, cause) => handleCellChange(row, col, value, cause)}
                                    defaultValue={Number(inputValue)}/>
    } else if (columns[columnMap[col]].type == 'SELECT') {
      return <DataSheetSelect options={columns[columnMap[col]].selectOptions}
                              value={inputValue}
                              defaultInputValue={inputValue}
                              onChange={option => handleSelectOnChange(option, row, col)}
                              modalRef={columns[columnMap[col]].modalRef}
      />
    } else {
      return <DataSheetTextInput value={inputValue}
                                 onChange={(value, cause) => handleCellChange(row, col, value, cause)}/>
    }
  }

  const renderTextCell = (row: number, col: string) => {
    if (columns[columnMap[col]]?.type == 'NUMBER') {
      return !Number.isNaN(Number(rows[row][col])) ? formatNumber(Number(rows[row][col])) : '';
    } else if (columns[columnMap[col]]?.type == 'AMOUNT') {
      return !Number.isNaN(Number(rows[row][col])) ? formatAmount(Number(rows[row][col])) : '';
    } else if (columns[columnMap[col]]?.type == 'PERCENT') {
      return !Number.isNaN(Number(rows[row][col])) ? formatPercentage(Number(rows[row][col])) : '';
    } else if (columns[columnMap[col]]?.type == 'SELECT') {
      return columns[columnMap[col]].selectOptions?.find(o => o.value === rows[row][col])?.label;
    } else if (columns[columnMap[col]]?.type == 'DERIVED') {
      return columns[columnMap[col]].deriveFunction?.(row) ?? '';
    } else {
      return rows[row][col];
    }
  };

  return <>
    <Table ref={tableRef}
           tabIndex={0}
           withTableBorder
           withColumnBorders
           verticalSpacing={1}
           className={"datasheet"}
           style={{
             maxWidth: columnWidths.reduce((acc, curr) => acc + curr, 0),
             height: 'fit-content'
           }}
           onFocus={focusTable}
           captionSide="top"
    >
      <Table.Caption>{caption}</Table.Caption>
      <Table.Thead>
        <Table.Tr>
          {showLineNumber &&
              <Table.Th style={{width: '1%'}}>#</Table.Th>}
          {columns.map((column, colIdx) => (
            <Table.Th key={colIdx}
                      onContextMenu={(e) => handleContextMenu(e, -1, column.name)}
                      style={{
                        maxWidth: columnWidths[colIdx] + 'px',
                        textAlign: 'center'
                      }}>
              {column.displayName}
            </Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {rows.map((row, rowIdx) => (
          <Table.Tr key={rowIdx}>
            {showLineNumber
              ? <Table.Td key={-1} style={{textAlign: 'center', padding: '0 5px 0 5px'}}
                          className={'lineNumberCol' + isValidRow(rowIdx) ? 'valid' : 'invalidValue'}>
                {rowIdx + 1}
              </Table.Td>
              : <></>
            }
            {columns.map(({name: col, align, validityChecker}, colIdx) => (
              <Table.Td key={colIdx}
                        className={getCellClassNames(rowIdx, col, validityChecker?.(row[col]) ?? true)}
                        onDoubleClick={(e) => handleCellDoubleClick(e, rowIdx, col)}
                        onContextMenu={(e) => handleContextMenu(e, rowIdx, col)}
                        onMouseDown={(e) => handleCellMouseDown(e, rowIdx, col)}
                        onMouseMove={(e) => handleCellMouseMove(e, rowIdx, col)}
                        onMouseUp={(e) => handleCellMouseUp(e, rowIdx, col)}
                        style={{
                          minWidth: columnWidths[colIdx] + 'px',
                          maxWidth: columnWidths[colIdx] + 'px',
                          userSelect: 'none',
                          textAlign: align,
                        }}
              >
                {isCellEdited(rowIdx, col)
                  ? renderInputCell(rowIdx, col)
                  : renderTextCell(rowIdx, col)}
              </Table.Td>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>

    <DataSheetContextMenu ref={contextMenuRef}
                          onSuccess={({selectedMenuItem, row, col, searchValue, replaceValue, copyValue}) => {
                            if (selectedMenuItem === 'CUT') {
                              handleCut();
                            } else if (selectedMenuItem === 'COPY') {
                              handleCopy();
                            } else if (selectedMenuItem === 'COPY_SELECTED_SUM') {
                              handleCopyValue(copyValue ?? '');
                            } else if (selectedMenuItem === 'PASTE') {
                              // TODO:
                            } else if (selectedMenuItem === 'INSERT_ROW_ABOVE') {
                              const rowsAbove = rows.filter((_, idx) => idx < row);
                              const rowsBelow = rows.filter((_, idx) => idx >= row);
                              updateRows([...rowsAbove, {} as Row, ...rowsBelow]);
                            } else if (selectedMenuItem === 'INSERT_ROW_BELOW') {
                              const rowsAbove = rows.filter((_, idx) => idx <= row);
                              const rowsBelow = rows.filter((_, idx) => idx > row);
                              updateRows([...rowsAbove, {} as Row, ...rowsBelow]);
                            } else if (selectedMenuItem === 'DELETE_ROW') {
                              const selectedRows = selectedCells.map(c => c.row).sort((a, b) => a - b);
                              const row1 = selectedRows[0];
                              const row2 = selectedRows.reverse()[0];
                              const rowsAbove = rows.filter((_, idx) => idx < row1);
                              const rowsBelow = rows.filter((_, idx) => idx > row2);
                              updateRows([...rowsAbove, ...rowsBelow]);
                              setSelectedCells([]);
                            } else if (selectedMenuItem === 'SEARCH_AND_REPLACE' && searchValue && replaceValue) {
                              const newRows = [...rows];
                              selectedCells.forEach(c => {
                                const cellValue = rows[c.row][c.col] ?? '';
                                newRows[c.row] = {
                                  ...newRows[c.row],
                                  [c.col]: cellValue.replace(searchValue, replaceValue)
                                }
                                updateRows(newRows);
                              });
                            }
                          }}/>
  </>
}
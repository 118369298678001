import {Button, Divider, Flex, Grid, Modal, Space, Table, Text} from "@mantine/core";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {ApiClient, ApiFile, FileStatus} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {Box, mapToBox} from "./canvas/canvas";
import {FileCanvasReadonly} from "./canvas/FileCanvasReadonly";
import {PageControl} from "./PageControl";
import {ExtractionModal} from "./extraction/ExtractionModal";
import {IconAd2, IconColumns3, IconDotsVertical, IconLink, IconPlus, IconTrashX, IconUnlink} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import {ActionMenu} from "../../../../common/actionButton/ActionMenu";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {DocumentLink} from "../../../../common/DocumentLink";
import {ActionIconButton} from "../../../../common/actionButton/ActionIconButton";

interface Props {
  onSuccess?: () => void
  onClose?: () => void
}

export const FileModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const extractionModalRef = useRef<any>();

  const [fileId, setFileId] = useState<string | undefined>();
  const [file, setFile] = useState<ApiFile | undefined>();

  const [page, setPage] = useState(0);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [boxes, setBoxes] = useState<Box[]>([]);

  const fetchFile = () => {
    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.getFile(organisationId, fileId)
        .then(resp => {
          setFile(resp.data);
          setBoxes(resp.data.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
        })
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchFile();
  }, [organisationId, fileId]);

  const openModal = useCallback((fileId?: string) => {
    setFileId(fileId);
    open();
  }, [open, fileId]);

  const closeModal = useCallback(() => {
    setFileId(undefined);
    setFile(undefined);
    setPage(0);
    setImage(new Image());
    onClose?.();
    close();
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const fetchPageImage = () => {
    if (!organisationId || !file) {
      return;
    }
    setLoading(true);
    Promise.all([
      ApiClient.getFilePageImage(organisationId, file.id, page),
    ])
      .then(([fileResp]) => {
        const img = new Image();
        img.onload = () => setImage(img);
        img.src = 'data:image/png;base64,' + fileResp.data;
      })
      .then(() => {
        setBoxes(file.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPageImage();
  }, [file, page]);

  const openExtractionModal = (fileId: string, extractionId?: string) => {
    extractionModalRef.current.openModal(fileId, extractionId);
  }

  const updateFileStatus = (status: FileStatus) => {
    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.updateFileStatus(organisationId, fileId, status)
        .then(fetchFile)
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false))
    }
  }

  const createInvoiceFromExtraction = (extractionId: string) => {
    if (organisationId) {
      setLoading(true);
      ApiClient.createInvoiceFromExtraction(organisationId, extractionId)
        .then(fetchFile)
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false))
    }
  }

  const unlinkExtractionDocument = (extractionId: string) => {
    if (organisationId) {
      setLoading(true);
      ApiClient.unlinkExtractionDocument(organisationId, extractionId)
        .then(fetchFile)
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false))
    }
  }

  console.log(file?.extractions);

  return <>
    <Modal opened={opened}
           id="file_modal"
           onClose={closeModal}
           title={`File: [${file?.id}]`}
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size={"auto"}
    >
      <Flex align="center" direction="column" gap="md">

        <Flex direction="row" gap="md">

          <Flex direction="column">
            <PageControl pageCount={file?.ocrResponse.pages.length ?? 0}
                         currentPage={page}
                         onPageChange={(page) => setPage(page)}/>
            <FileCanvasReadonly image={image} boxes={boxes} width={900} height={window.innerHeight * 0.75}/>
          </Flex>

          <div style={{width: '700px'}}>
            <Flex direction="column">
              <Flex direction={"column"} gap="md" style={{width: '100%', height: '2rem'}} align={"center"}>
                <Text c="dimmed" size="sm">General</Text>
              </Flex>

              <Grid style={{width: '30rem'}} gutter="sm" align={"center"}>
                <Grid.Col span={2}>
                  <Text size="sm">Status: </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <StatusBadge status={file?.status}/>
                </Grid.Col>

                <Grid.Col span={2}>
                  <Text size="sm">Name: </Text>
                </Grid.Col>

                <Grid.Col span={10}>
                  <Text size="sm">{file?.name} </Text>
                </Grid.Col>

              </Grid>

              <Space h={20}/>
              <Divider/>
              <Space h={20}/>

            </Flex>

            <Table style={{width: "100%", minWidth: "400px"}}
                   verticalSpacing={1}
                   withTableBorder
                   withColumnBorders
                   highlightOnHover={true}
                   captionSide="top">
              <Table.Caption>Extractions</Table.Caption>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th style={{width: "1%", textAlign: "right"}}>
                    <IconColumns3 style={{marginTop: '5px'}}/>
                  </Table.Th>
                  <Table.Th style={{textAlign: 'center'}}>Name</Table.Th>
                  <Table.Th style={{textAlign: 'center'}}>Type</Table.Th>
                  <Table.Th style={{textAlign: 'center'}}>Status</Table.Th>
                  <Table.Th style={{textAlign: 'center'}}>Document</Table.Th>
                  <Table.Th style={{textAlign: 'center'}}>Action</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {file?.extractions?.map((extraction, idx) => (
                  <Table.Tr key={idx}>
                    <Table.Td style={{textAlign: "center"}}
                              onClick={() => openExtractionModal(extraction.fileId, extraction.id)}>
                      {idx + 1}
                    </Table.Td>
                    <Table.Td onClick={() => openExtractionModal(extraction.fileId, extraction.id)}>
                      {extraction.description}
                    </Table.Td>
                    <Table.Td style={{textAlign: "center"}}
                              onClick={() => openExtractionModal(extraction.fileId, extraction.id)}>
                      {extraction.documentType}
                    </Table.Td>
                    <Table.Td style={{textAlign: "center"}}
                              onClick={() => openExtractionModal(extraction.fileId, extraction.id)}>
                      <StatusBadge status={extraction.status}/>
                    </Table.Td>
                    <Table.Td style={{textAlign: "center"}}
                              onClick={() => openExtractionModal(extraction.fileId, extraction.id)}>
                      <DocumentLink type={extraction.documentType} id={extraction.documentId}/>
                    </Table.Td>
                    <Table.Td style={{textAlign: "center"}}
                              onClick={() => openExtractionModal(extraction.fileId, extraction.id)}>
                      <ActionIconButton
                        label={<IconDotsVertical size='12px' stroke={1.5}/>}
                        size="sm"
                        options={[
                          {
                            label: 'Create document (incoming invoice)',
                            action: () => createInvoiceFromExtraction(extraction.id),
                            leftSection: <IconLink size="1.5rem" stroke={1.5}/>,
                            disabled: !!extraction.documentId
                          },
                          {
                            label: 'Unlink document',
                            action: () => unlinkExtractionDocument(extraction.id),
                            leftSection: <IconUnlink size="1.5rem" stroke={1.5}/>,
                            disabled: !extraction.documentId
                          },
                          'DIVIDER',
                          {
                            label: 'Delete',
                            action: () => null,
                            leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
                            disabled: !extraction.documentId
                          }
                        ]}
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>


            <Space h={20}/>

            <Flex direction="row" align="center" justify={"right"}>
              <Button size="xs" rightSection={<IconPlus/>} onClick={() => openExtractionModal(fileId ?? '')}>
                New extraction
              </Button>
            </Flex>

            <ActionMenu label={"Actions"}
                        options={[
                          {
                            label: <Flex dir="row" gap="xs">Mark as <StatusBadge status="IN_PROGRESS"/></Flex>,
                            action: () => updateFileStatus('IN_PROGRESS'),
                            leftSection: <IconAd2 size="1.5rem" stroke={1.5}/>,
                            disabled: file?.status !== 'EXTRACTED'
                          },
                          {
                            label: <Flex dir="row" gap="xs">Mark as <StatusBadge status="EXTRACTED"/></Flex>,
                            action: () => updateFileStatus('EXTRACTED'),
                            leftSection: <IconAd2 size="1.5rem" stroke={1.5}/>,
                            disabled: file?.status !== 'IN_PROGRESS'
                          },
                          'DIVIDER',
                          {
                            label: 'Delete',
                            action: () => null,
                            leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
                          },
                        ]}/>
          </div>
        </Flex>
      </Flex>
    </Modal>

    <ExtractionModal ref={extractionModalRef} onSuccess={() => fetchFile()} />
  </>
});

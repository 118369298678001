import {Space, Stack, Table} from "@mantine/core";
import {FileUploadButton} from "./FileUploadButton";
import {IconColumns3} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import React, {RefObject} from "react";
import {ApiFile} from "../../../../utils/http/apiClient";

interface Props {
  files: ApiFile[]
  handleFileUpload: (files: FileList | null) => void
  fileUploadModalRef: RefObject<any>
}

export const FilesTab = ({files, handleFileUpload, fileUploadModalRef}: Props) => {

  return <>
    <Space h={10}/>
    <Stack dir="row" align="flex-start" justify="space-around">
      <FileUploadButton onFileChange={handleFileUpload}/>

      <Table style={{width: "1%", minWidth: "700px"}}
             verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Status</Table.Th>
            <Table.Th style={{textAlign: "left"}}>Name</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Type</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {files.map((file, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {idx + 1}
              </Table.Td>
              <Table.Td onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                <StatusBadge status={file.status}/>
              </Table.Td>
              <Table.Td onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {file.name}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {file.type}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  </>;
}
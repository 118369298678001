import {Select} from "@mantine/core";
import React, {memo, useRef} from "react";
import {ApiContact} from "../../utils/http/apiClient";
import {ContactModal} from "../../pages/private/organisation/contacts/ContactModal";

export interface Props {
  contactId?: string
  contacts: ApiContact[],
  onChange: (value?: string) => void
  onNewCreated: (newContact: ApiContact) => void
  inputLabel?: string
}

export const ContactSelect = memo(({
                                     contactId,
                                     contacts,
                                     onChange,
                                     onNewCreated,
                                     inputLabel
                                   }: Props) => {
  const modalRef = useRef<any>();

  const options = [];
  options.push({label: "+ Create new", value: "CREATE"});
  options.push(
    ...contacts?.filter(contact => contact.active || contactId === contact.id)
      .map(contact => ({label: contact.name ?? 'MISSING', value: contact.id})));

  const handleChange = (value: string | null) => {
    if (value === 'CREATE') {
      modalRef.current?.openModal(undefined);
    } else {
      onChange(value ?? undefined);
    }
  }

  return <>
    <Select clearable
            value={contactId ?? null}
            data={options}
            onChange={handleChange}
            checkIconPosition="right"
            searchable
            hiddenInputProps={{
              autoFocus: true
            }}
            allowDeselect={false}
            placeholder={"Select " + (inputLabel?.toLowerCase() ?? "contact")}
            styles={{
              input: {
                minHeight: "24px",
                maxHeight: "24px",
              }
            }}
    />

    <ContactModal ref={modalRef} onSuccess={onNewCreated}/>
  </>

});
import {Box, Button, Flex, LoadingOverlay, Space, Table, Title} from "@mantine/core";
import {IconColumns3, IconPlus, IconReceipt} from "@tabler/icons-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ApiClient, ApiContact, ApiIncomingInvoice} from "../../../../../utils/http/apiClient";
import {createIdMap} from "../../../../../utils/objectUtils";
import {useOrganisationId} from "../../../../../hooks/useOrganisationId";
import {formatAmount} from "../../../../../utils/formatUtils";
import {formatDate} from "../../../../../utils/date/dateUtils";
import {IncomingInvoiceModal} from "./IncomingInvoiceModal";
import {StatusBadge} from "../../../../../common/StatusBadge";
import {DocumentLink} from "../../../../../common/DocumentLink";

export const IncomingInvoicesList = () => {
  const modalRef = useRef<any>();
  const organisationId = useOrganisationId();
  const [invoices, setInvoices] = useState<ApiIncomingInvoice[]>([]);
  const [contactsById, setContactsById] = useState<Record<string, ApiContact>>({});
  const [loading, setLoading] = useState(false);

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getIncomingInvoices(organisationId),
        ApiClient.getContacts(organisationId)
      ]).then(([invoices, parties]) => {
        setInvoices(invoices.data);
        setContactsById(createIdMap(parties.data));
      }).finally(() => setLoading(false));
    }
  }, [organisationId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return <>
    <Flex direction="row" align="center">
      <IconReceipt size={35} stroke={1.5}/>
      <Title size={24}>
        Incoming invoices
      </Title>
      <Space w={20}/>
      <Button size="xs" rightSection={<IconPlus/>} onClick={() => modalRef.current?.openModal(undefined)}>
        New
      </Button>
    </Flex>

    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>
      <Table style={{width: "1%", minWidth: "900px"}}
             verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className="datatable">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Status</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Booking date</Table.Th>
            <Table.Th style={{textAlign: "left"}}>Supplier</Table.Th>
            <Table.Th style={{textAlign: "left"}}>Invoice No.</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Amount</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Currency</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Issue date</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Due date</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Delivery date</Table.Th>

          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {invoices.map((invoice, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {idx + 1}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                <StatusBadge status={invoice.bookingStatus}/>
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {formatDate(invoice.bookingDate)}
              </Table.Td>
              <Table.Td style={{textAlign: "left"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {contactsById[invoice.supplierId ?? '']?.name}
              </Table.Td>
              <Table.Td onClick={() => modalRef.current?.openModal(invoice.id)}>
                {invoice.invoiceNumber}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {formatAmount(invoice.grossAmount ?? NaN)}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {invoice.currency}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {formatDate(invoice.issueDate)}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {formatDate(invoice.dueDate)}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(invoice.id)}>
                {formatDate(invoice.deliveryDate)}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>

    <IncomingInvoiceModal ref={modalRef} onSuccess={fetchEntities}/>
  </>
}
import React, {MouseEvent, useCallback, useEffect, useRef, useState} from "react";
import {
  Box,
  BoxGroup,
  boxGroupContains,
  drawBoxes,
  groupIntoLines,
  isBoxBeingSelected,
  mapToBoxGroup,
  mergeBoxGroups,
  Point,
  removeBox
} from "./canvas";
import {FileCanvasContextMenu} from "./FileCanvasContextMenu";

type Mode = 'SELECTION' | 'MERGE' | 'REMOVE_GROUP' | 'TOGGLE_BOX_IN_GROUP';

const BORDER_COLORS = {
  'SELECTION': 'black',
  'MERGE': 'blue',
  'REMOVE_GROUP': 'red',
  'TOGGLE_BOX_IN_GROUP': 'purple',
};

interface Props {
  image: HTMLImageElement | null
  boxes: Box[],
  width: number
  height: number
}

export const FileCanvasReadonly = ({image, boxes, width, height}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [zoom, setZoom] = useState(1);
  const [leftOffset, setLeftOffset] = useState(0);
  const [topOffset, setTopOffset] = useState(0);

  const [mousePosition, setMousePosition] = useState<Point | null>();
  const [dragStartPoint, setDragStartPoint] = useState<Point | null>();

  const [cursor, setCursor] = useState('grab');

  const draw = useCallback(() => {
    if (!canvasRef || !image) {
      return;
    }
    const context = canvasRef.current?.getContext('2d');
    if (!context) {
      return;
    }

    context.fillStyle = "gray";
    context.fillRect(0, 0, image.width, image.height);
    context.drawImage(image, leftOffset, topOffset, image.width * zoom, image.height * zoom);

  }, [canvasRef, image, boxes, zoom, leftOffset, topOffset]);

  useEffect(() => {
    draw();
  }, [draw]);

  const onCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
    event.preventDefault();

    if (event.button !== 0) {
      return; // Ignore right click
    }

    setDragStartPoint({x: event.clientX, y: event.clientY} as Point);
    setCursor('grabbing');
  }

  const onCanvasMouseUp = (event: MouseEvent<HTMLCanvasElement>) => {
    if (event.button !== 0) {
      return; // Ignore right click
    }
    setDragStartPoint(null);
    setCursor('grab');
  }

  const onCanvasDoubleClick = (event: MouseEvent<HTMLCanvasElement>) => {
    setCursor('grab');
  }


  const onCanvasMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    let bound = canvasRef.current?.getBoundingClientRect();
    if (bound && canvasRef.current) {
      const x = (event.clientX - bound.left - canvasRef.current?.clientLeft) / zoom;
      const y = (event.clientY - bound.top - canvasRef.current?.clientTop) / zoom;
      setMousePosition({x, y} as Point);
    }

    if (dragStartPoint) {
      const deltaX = event.clientX - dragStartPoint.x;
      const deltaY = event.clientY - dragStartPoint.y;
      setLeftOffset(leftOffset + deltaX);
      setTopOffset(topOffset + deltaY);
      setDragStartPoint({x: event.clientX, y: event.clientY} as Point);
    }
  }

  const wheelHandler = useCallback((event: WheelEvent) => {
    if (event.shiftKey) {
      event.preventDefault();
      if (event.deltaY < 0) {
        setZoom(zoom + 0.1);
      } else {
        setZoom(zoom - 0.1);
      }
    }
  }, [image, zoom, mousePosition]);

  useEffect(() => {
    canvasRef?.current?.addEventListener('wheel', wheelHandler, {passive: false});
    return () => {
      canvasRef?.current?.removeEventListener('wheel', wheelHandler);
    }
  }, [canvasRef, wheelHandler]);

  return <>
    <canvas ref={canvasRef}
            width={width}
            height={height}
            onMouseMove={onCanvasMouseMove}
            onMouseDown={onCanvasMouseDown}
            onMouseUp={onCanvasMouseUp}
            onDoubleClick={onCanvasDoubleClick}
            tabIndex={1}
            style={{
              border: '2px solid black',
              width: `${width}px`,
              height: `${height}px`,
              cursor: cursor,
            }}
    />
  </>
}
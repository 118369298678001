import {useOrganisationId} from "../../../../../hooks/useOrganisationId";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ApiClient, ApiRegister, ApiRegisterReport} from "../../../../../utils/http/apiClient";
import {IconColumns3, IconPlus, IconReportMoney} from "@tabler/icons-react";
import {Box, Button, Flex, LoadingOverlay, Space, Table, Title} from "@mantine/core";
import {createIdMap} from "../../../../../utils/objectUtils";
import {formatDate} from "../../../../../utils/date/dateUtils";
import {formatAmount} from "../../../../../utils/formatUtils";
import {RegisterReportModal} from "./RegisterReportModal";
import {StatusBadge} from "../../../../../common/StatusBadge";

export const RegisterReportList = () => {
  const organisationId = useOrganisationId();
  const [registersById, setRegistersById] = useState<Record<string, ApiRegister>>({});
  const [registerReports, setRegisterReports] = useState<ApiRegisterReport[]>([]);
  const modalRef = useRef<any>();
  const [loading, setLoading] = useState(false);

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getRegisters(organisationId),
        ApiClient.getRegisterReports(organisationId)
      ]).then(([registers, reports]) => {
        setRegistersById(createIdMap(registers.data));
        setRegisterReports(reports.data);
      }).finally(() => setLoading(false));
    }
  }, [organisationId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return <>
    <Flex direction="row" align="center">
      <IconReportMoney/>
      <Title size={24}>
        Register reports
      </Title>
      <Space w={20}/>
      <Button size="xs" rightSection={<IconPlus/>} onClick={() => modalRef.current?.openModal(undefined)}>
        New
      </Button>
    </Flex>
    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>
      <Table style={{width: "1%", minWidth: "600px"}}
             verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className={'datatable'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Status</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Booking date</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Register</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Date</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Amount</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {registerReports.map((report, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(report.id)}>
                {idx + 1}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}  onClick={() => modalRef.current?.openModal(report.id)}>
                <StatusBadge status={report.bookingStatus}/>
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}  onClick={() => modalRef.current?.openModal(report.id)}>
                {formatDate(report.bookingDate)}
              </Table.Td>
              <Table.Td onClick={() => modalRef.current?.openModal(report.id)}>
                {registersById[report.registerId ?? '']?.name ?? ''}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(report.id)}>
                {formatDate(report.date)}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}} onClick={() => modalRef.current?.openModal(report.id)}>
                {report.grossAmount ? formatAmount(report.grossAmount) : ''}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>

    <RegisterReportModal ref={modalRef} onSuccess={fetchEntities}/>

  </>;
}

import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
  ApiClient,
  ApiContact,
  ApiExtraction,
  ApiExtractionBody,
  ApiExtractionLineItem,
  ApiExtractionTaxItem,
  ApiFile,
  ApiUpsertExtractionRequest,
  ExtractionStatus
} from "../../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../../hooks/useOrganisationId";
import {useDisclosure} from "@mantine/hooks";
import {Button, Checkbox, Divider, Flex, Grid, LoadingOverlay, Modal, Space, Text} from "@mantine/core";
import {IconAd2, IconCircleX, IconDeviceFloppy} from "@tabler/icons-react";
import {createIdMap, roundAmount} from "../../../../../utils/objectUtils";
import {Option} from "../../../../../common/datasheet/DataSheetSelect";
import {cleanNumber, deriveUnitAndQuantity, isNumeric, isUnit, mapInvoiceItems} from "./extractionsUtils";
import {DataSheet} from "../../../../../common/datasheet/DataSheet";
import {PageControl} from "../PageControl";
import {FileCanvas} from "../canvas/FileCanvas";
import {Box, mapToBox} from "../canvas/canvas";
import {formatAmount} from "../../../../../utils/formatUtils";
import {ContactSelect} from "../../../../../common/select/ContactSelect";
import {TextInput} from "../../../../../common/TextInput";
import {DateInput} from "../../../../../common/DateInput";
import dayjs from "dayjs";
import {StatusBadge} from "../../../../../common/StatusBadge";
import {DocumentLink} from "../../../../../common/DocumentLink";
import {notifyError, notifySavedChanges} from "../../../../../utils/notificationUtils";
import {ActionButton} from "../../../../../common/actionButton/ActionButton";

interface Props {
  onSuccess?: () => void
  onCancel?: () => void
}

const mapExtraction = (existing?: ApiExtraction) => {
  if (existing) {
    return existing;
  }
  return {
    documentType: "INCOMING_INVOICE",
    status: 'CREATING',
    description: 'Default',
    body: {
      discountInPercentage: true,
      discountIncludedInAmounts: true,
      lineItems: [...Array(3)].map(_ => ({} as ApiExtractionLineItem)),
      taxItems: [...Array(3)].map(_ => ({} as ApiExtractionTaxItem))
    } as ApiExtractionBody
  } as ApiExtraction;
}

export const ExtractionModal = React.forwardRef(({onSuccess, onCancel}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<ApiFile | undefined>(undefined);
  const [extractionId, setExtractionId] = useState<string | undefined>(undefined);
  const [extraction, setExtraction] = useState<ApiExtraction | undefined>({} as ApiExtraction);
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [itemOptions, setItemOptions] = useState<Option[]>([]);
  const [contacts, setContacts] = useState<ApiContact[]>([]);

  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [page, setPage] = useState(0);

  const modalRefs = {
    item: useRef<any>()
  };

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        extractionId
          ? ApiClient.getExtraction(organisationId, extractionId).then(resp => resp.data)
          : Promise.resolve(undefined),
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getUnits(organisationId).then(resp => resp.data),
        ApiClient.getContacts(organisationId).then(resp => resp.data),
      ]).then(([fileExtraction, items, units, contacts]) => {
        setExtraction(mapExtraction(fileExtraction));
        setContacts(contacts);
        const unitsById = createIdMap(units);
        const itemsOptions = items.map(i => ({
          value: i.id,
          label: `${i.name} [${unitsById[i.unitId ?? '']?.shortName ?? ''}]`
        } as Option));
        setItemOptions(itemsOptions);
      }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, extractionId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const fetchFile = () => {
    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.getFile(organisationId, fileId)
        .then(resp => {
          setFile(resp.data);
          setBoxes(resp.data.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
        })
        .catch(notifyError)
        .finally(() => {
          setLoading(false);
        })
    }
  };

  useEffect(() => {
    fetchFile();
  }, [organisationId, fileId]);

  const fetchPageImage = () => {
    if (!organisationId || !file) {
      return;
    }
    setLoading(true);
    ApiClient.getFilePageImage(organisationId, file.id, page)
      .then((fileResp) => {
        const img = new Image();
        img.onload = () => setImage(img);
        img.src = 'data:image/png;base64,' + fileResp.data;
      })
      .then(() => {
        setBoxes(file.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPageImage();
  }, [file, page]);

  const openModal = useCallback((fileId: string, extractionId?: string) => {
    setFileId(fileId);
    setExtractionId(extractionId);
    open();
  }, [open]);

  const closeModal = useCallback((success: boolean) => {
    if (success) {
      onSuccess?.();
    }
    setFileId(undefined);
    setExtractionId(undefined);
    setPage(0);
    setImage(new Image());
    close();
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (newStatus?: ExtractionStatus) => {
    const request = {
      fileId: fileId,
      documentType: extraction?.documentType ?? 'INCOMING_INVOICE',
      description: extraction?.description,
      extractionBody: {
        supplierId: extraction?.body?.supplierId,
        invoiceNumber: extraction?.body?.invoiceNumber,
        issueDate: extraction?.body?.issueDate,
        dueDate: extraction?.body?.dueDate,
        deliveryDate: extraction?.body?.deliveryDate,
        netAmount: extraction?.body?.netAmount,
        taxAmount: extraction?.body?.taxAmount,
        grossAmount: extraction?.body?.grossAmount,
        lineItems: extraction?.body?.lineItems ?? [],
        taxItems: extraction?.body?.taxItems ?? [],
        discountInPercentage: discountInPercentage,
        discountIncludedInAmounts: discountIncludedInAmounts
      } as ApiExtractionBody
    } as ApiUpsertExtractionRequest;

    if (organisationId && fileId) {
      setLoading(true);
      (extraction?.id
          ? ApiClient.updateExtraction(organisationId, extraction.id, request)
          : ApiClient.createExtraction(organisationId, request)
      )
        .then((resp) =>
          newStatus
            ? ApiClient.updateExtractionStatus(organisationId, resp.data.id, newStatus)
            : null)
        .then(notifySavedChanges)
        .then(() => closeModal(true))
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  const setBodyValue = (key: string, value: any) => {
    setBodyValues([{key, value}]);
  }

  const setBodyValues = (fields: { key: string, value: any }[]) => {
    let updateExtraction = {...extraction} as ApiExtraction;
    for (const field of fields) {
      updateExtraction = applyBodyField(updateExtraction, field.key, field.value);
    }
    setExtraction(updateExtraction);
  }

  const applyBodyField = (fileExtraction: ApiExtraction, key: string, value: any) => {
    const lineItems = mapInvoiceItems(
      key === 'lineItems' ? value : fileExtraction?.body?.lineItems ?? [],
      key === 'discountInPercentage' ? value : discountInPercentage,
      key === 'discountIncludedInAmounts' ? value : discountIncludedInAmounts);

    return {
      ...fileExtraction,
      body: {
        ...fileExtraction?.body,
        [key]: value,
        lineItems: lineItems
      }
    } as ApiExtraction;
  }

  const discountInPercentage = extraction?.body?.discountInPercentage ?? true;
  const discountIncludedInAmounts = extraction?.body?.discountIncludedInAmounts ?? true;
  const setDiscountInPercentage = (checked: boolean) => setBodyValue('discountInPercentage', checked);
  const setDiscountIncludedInAmounts = (checked: boolean) => setBodyValue('discountIncludedInAmounts', checked);

  const [computedTaxItems, setComputedTaxItems] = useState<ApiExtractionTaxItem[]>([]);
  const [computedTotals, setComputedTotals] = useState([{
    name: 'Total',
    netAmount: '',
    taxAmount: '',
    grossAmount: ''
  }]);

  useEffect(() => {
    const lineItems = (extraction?.body?.lineItems ?? []);
    const taxRates = new Set(lineItems.map(li => li.tax));
    const netAmounts: Record<string, number> = {};
    const taxAmounts: Record<string, number> = {};
    const grossAmounts: Record<string, number> = {};
    lineItems.forEach(li => {
      const taxRate = li.tax;
      if (!taxRate) {
        return;
      }
      netAmounts[taxRate] = taxRate in netAmounts ? netAmounts[taxRate] : 0;
      grossAmounts[taxRate] = taxRate in grossAmounts ? grossAmounts[taxRate] : 0;
      taxAmounts[taxRate] = taxRate in taxAmounts ? taxAmounts[taxRate] : 0;
      netAmounts[taxRate] += Number(li.mappedNetAmount);
      grossAmounts[taxRate] += Number(li.mappedGrossAmount);
      taxAmounts[taxRate] += (Number(li.mappedGrossAmount) - Number(li.mappedNetAmount));
    });
    const taxItems = Array.from(taxRates).map(tr => ({
      rate: tr,
      netAmount: formatAmount(roundAmount(netAmounts[tr])),
      taxAmount: formatAmount(roundAmount(taxAmounts[tr])),
      grossAmount: formatAmount(roundAmount(grossAmounts[tr])),
    } as ApiExtractionTaxItem));

    setComputedTaxItems(taxItems);
    setComputedTotals([{
      name: 'Total',
      netAmount: formatAmount(taxItems.map(ti => Number(ti.netAmount)).reduce((acc, curr) => acc + curr, 0)),
      taxAmount: formatAmount(taxItems.map(ti => Number(ti.taxAmount)).reduce((acc, curr) => acc + curr, 0)),
      grossAmount: formatAmount(taxItems.map(ti => Number(ti.grossAmount)).reduce((acc, curr) => acc + curr, 0)),
    }]);
  }, [extraction]);

  const [totals, setTotals] = useState([{name: 'Total', netAmount: '', taxAmount: '', grossAmount: ''}]);
  useEffect(() => {
    setTotals([{
      name: 'Total',
      netAmount: extraction?.body?.netAmount ?? '',
      taxAmount: extraction?.body?.taxAmount ?? '',
      grossAmount: extraction?.body?.grossAmount ?? '',
    }]);
  }, [extraction]);

  const deriveUnitsAndQuantities = () => {
    const newLineItems = extraction?.body?.lineItems.map(lineItem => {
      const unitAndQuantity = deriveUnitAndQuantity(lineItem.text);
      return {
        ...lineItem,
        unit1: unitAndQuantity.unit,
        quantity1: unitAndQuantity.quantity
      } as ApiExtractionLineItem
    });
    setBodyValue("lineItems", newLineItems);
  }

  return <Modal opened={opened}
                id="file_extraction_modal"
                onClose={() => closeModal(false)}
                title={`Extraction: [${extraction?.id ?? 'CREATING'}]`}
                closeOnClickOutside={false}
                transitionProps={{duration: 100}}
                overlayProps={{opacity: 0.5}}
                fullScreen={true}
                styles={{
                  header: {
                    width: `${window.outerWidth}px`
                  }
                }}
  >
    <LoadingOverlay visible={loading}/>
    <Flex align="center" direction="column" gap="md">
      <Flex direction="row" gap="md">
        <Flex direction="column"
              style={{
                position: 'sticky',
                top: 60,
                height: '100%',
                maxHeight: `${window.innerHeight - 150}}px`
              }}>
          <PageControl pageCount={file?.ocrResponse.pages.length ?? 0}
                       currentPage={page}
                       onPageChange={(page) => setPage(page)}/>
          <FileCanvas image={image} boxes={boxes} width={600} height={window.innerHeight - 150}/>
        </Flex>

        <Flex direction="column">
          <Flex direction={"column"} gap="md" style={{width: '37rem', height: '2rem'}} align={"center"}>
            <Text c="dimmed" size="sm">General</Text>
          </Flex>

          <Grid style={{width: '23rem'}} gutter="sm" align="center">
            <Grid.Col span={4}>
              <Text size="sm">Status: </Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <StatusBadge status={extraction?.status}/>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text size="sm">Description: </Text>
            </Grid.Col>

            <Grid.Col span={8}>
              <TextInput value={extraction?.description ?? null}
                         onChange={value => setExtraction({
                           ...extraction,
                           description: value
                         } as ApiExtraction)}/>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text size="sm">Linked document: </Text>
            </Grid.Col>
            <Grid.Col span={8}>
              {extraction?.documentId
                ? <DocumentLink type='INCOMING_INVOICE' id={extraction?.documentId}/>
                : <Text size="sm">N/A</Text>}
            </Grid.Col>

          </Grid>

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <Flex direction={"column"} gap="md" style={{width: '37rem', height: '2rem'}} align={"center"}>
            <Text c="dimmed" size="sm">Supplier & dates</Text>
          </Flex>
          <Flex direction={"row"} gap="md">
            <Grid style={{width: '23rem'}} gutter="sm">
              <Grid.Col span={4}>
                <Text size="sm">Supplier: </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <ContactSelect contactId={extraction?.body?.supplierId ?? undefined}
                               contacts={contacts}
                               onChange={contactId => setBodyValue('supplierId', contactId)}
                               onNewCreated={fetchEntities}/>
              </Grid.Col>

              <Grid.Col span={4}>
                <Text size="sm">Invoice number: </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <TextInput label="Invoice Number"
                           value={extraction?.body?.invoiceNumber ?? null}
                           onChange={vale => setBodyValue('invoiceNumber', vale)}/>
              </Grid.Col>

            </Grid>
            <Grid style={{width: '14rem'}} gutter="sm">
              <Grid.Col span={5}>
                <Text size="sm">Issue date: </Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <DateInput
                  value={extraction?.body?.issueDate ? dayjs(extraction?.body?.issueDate).toDate() : null}
                  onChange={date => setBodyValue('issueDate', date)}/>
              </Grid.Col>

              <Grid.Col span={5}>
                <Text size="sm">Due date: </Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <DateInput
                  value={extraction?.body?.dueDate ? dayjs(extraction?.body?.dueDate).toDate() : null}
                  onChange={date => setBodyValue('dueDate', date)}/>
              </Grid.Col>

              <Grid.Col span={5}>
                <Text size="sm">Delivery date: </Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <DateInput
                  value={extraction?.body?.deliveryDate ? dayjs(extraction?.body?.deliveryDate).toDate() : null}
                  onChange={date => setBodyValue('deliveryDate', date)}/>
              </Grid.Col>

            </Grid>
          </Flex>

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <DataSheet
            caption={"Line items"}
            showLineNumber={true}
            columns={[
              {name: 'text', displayName: 'Text', type: 'TEXT', align: 'left'},
              {name: 'quantity1', displayName: 'Qty1', type: 'TEXT', align: 'right', validityChecker: isNumeric},
              {name: 'unit1', displayName: 'Unit1', type: 'TEXT', align: 'center', validityChecker: isUnit},
              {name: 'quantity2', displayName: 'Qty2', type: 'TEXT', align: 'right', validityChecker: isNumeric},
              {name: 'unit2', displayName: 'Unit2', type: 'TEXT', align: 'right', validityChecker: isUnit},
              {name: 'quantity3', displayName: 'Qty3', type: 'TEXT', align: 'right', validityChecker: isNumeric},
              {name: 'discount', displayName: 'Discount', type: 'TEXT', align: 'right', validityChecker: isNumeric},
              {name: 'tax', displayName: 'Tax', type: 'TEXT', align: 'right', validityChecker: isNumeric},
              {
                name: 'netAmount',
                displayName: 'Net Amount',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric
              },
              {
                name: 'grossAmount',
                displayName: 'Gross Amount',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric
              },
              {
                name: 'mappedItemId',
                displayName: 'Item [M]',
                type: 'SELECT',
                align: 'center',
                selectOptions: itemOptions,
                modalRef: modalRefs.item
              },
              {
                name: 'mappedQuantity',
                displayName: 'Qty [M]',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric,
                readOnly: true
              },
              {
                name: 'mappedUnit',
                displayName: 'Unit [M]',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric,
                readOnly: true
              },
              {
                name: 'mappedUnitPrice',
                displayName: 'Unit Price [M]',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric,
                readOnly: true
              },
              {
                name: 'mappedNetAmount',
                displayName: 'Net Amount [M]',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric,
                readOnly: true
              },
              {
                name: 'mappedGrossAmount',
                displayName: 'Gross Amount [M]',
                type: 'TEXT',
                align: 'right',
                validityChecker: isNumeric,
                readOnly: true
              },
            ]}
            rows={extraction?.body?.lineItems ?? []}
            updateRows={(rows) => {
              const newLineItems = rows.map(row => {
                const lineItem = {} as ApiExtractionLineItem;
                Object.keys(row).forEach(col => lineItem[col] = row[col])
                return lineItem;
              });
              // setBodyValue('lineItems', mapInvoiceItems(newLineItems, discountInPercentage, discountIncludedInAmounts));
              setBodyValue('lineItems', newLineItems);
            }}
            rowValidityCheckers={[
              (row) => {
                if (row['amountExclTax']) {
                  const quantity = Number(cleanNumber(row['quantity']));
                  const unitPrice = Number(cleanNumber(row['unitPrice']));
                  const discount = row['discount']
                    ? Number(cleanNumber(row['discount'])) / 100
                    : 0;
                  const amountExclTax = Number(cleanNumber(row['amountExclTax']));
                  const delta = Math.abs(quantity * unitPrice * (1 - discount) - amountExclTax);
                  return delta < 0.01;
                } else {
                  return true;
                }
              },
              (row) => {
                if (row['amountInclTax']) {
                  const quantity = Number(cleanNumber(row['quantity']));
                  const unitPrice = Number(cleanNumber(row['unitPrice']));
                  const discount = row['discount']
                    ? Number(cleanNumber(row['discount'])) / 100
                    : 0;
                  const tax = Number(cleanNumber(row['tax'])) / 100
                  const amountInclTax = Number(cleanNumber(row['amountInclTax']));
                  const delta = Math.abs(quantity * unitPrice * (1 - discount) * (1 + tax) - amountInclTax);
                  return delta < 0.01;
                } else {
                  return true;
                }
              }
            ]}
          />
          <Space h={20}/>

          <Flex direction={"row"} gap={5} justify={"right"} align={"center"}>
            <Button onClick={deriveUnitsAndQuantities} size="xs">Derive units and quantities</Button>
            <Checkbox checked={discountInPercentage}
                      onChange={e => setDiscountInPercentage(e.target.checked)}
                      label={"Discount in percentage"}/>
            <Checkbox checked={discountIncludedInAmounts}
                      onChange={e => setDiscountIncludedInAmounts(e.target.checked)}
                      label={"Discount already included in amounts"}/>

          </Flex>


          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <Flex direction="row" justify={"space-around"}>

            <Flex direction="column">
              <DataSheet
                caption={"VAT items"}
                columns={[
                  {name: 'rate', displayName: 'Tax Rate', type: 'TEXT', align: 'right', validityChecker: isNumeric},
                  {
                    name: 'netAmount',
                    displayName: 'Net Amount',
                    type: 'TEXT',
                    align: 'right',
                    validityChecker: isNumeric
                  },
                  {
                    name: 'taxAmount',
                    displayName: 'Tax Amount',
                    type: 'TEXT',
                    align: 'right',
                    validityChecker: isNumeric
                  },
                  {
                    name: 'grossAmount',
                    displayName: 'Gross Amount',
                    type: 'TEXT',
                    align: 'right',
                    validityChecker: isNumeric
                  },
                ]}
                rows={extraction?.body?.taxItems ?? []}
                updateRows={(rows) => {
                  const newVatItems = rows.map(row => {
                    const taxItem = {} as ApiExtractionTaxItem;
                    Object.keys(row).forEach(col => taxItem[col] = row[col])
                    return taxItem;
                  });
                  setBodyValue('taxItems', newVatItems);
                }}
                rowValidityCheckers={[
                  // TODO
                ]}
              />

              <Space h={20}/>

              <DataSheet
                caption={"Totals"}
                columns={[
                  {
                    name: 'name',
                    displayName: 'Name',
                    type: 'TEXT',
                    align: 'right',
                    readOnly: true
                  },
                  {
                    name: 'netAmount',
                    displayName: 'Net Amount',
                    type: 'TEXT',
                    align: 'right',
                    validityChecker: isNumeric
                  },
                  {
                    name: 'taxAmount',
                    displayName: 'Tax Amount',
                    type: 'TEXT',
                    align: 'right',
                    validityChecker: isNumeric
                  },
                  {
                    name: 'grossAmount',
                    displayName: 'Gross Amount',
                    type: 'TEXT',
                    align: 'right',
                    validityChecker: isNumeric
                  },
                ]}
                rows={totals}
                updateRows={(rows) => {
                  setBodyValues([
                    {key: 'netAmount', value: rows[0].netAmount},
                    {key: 'taxAmount', value: rows[0].taxAmount},
                    {key: 'grossAmount', value: rows[0].grossAmount},
                  ]);
                }}
                rowValidityCheckers={[]}
              />
            </Flex>

            <Flex direction="column">
              <DataSheet
                caption={"Calculated VAT summary"}
                columns={[
                  {name: 'rate', displayName: 'Tax Rate', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'netAmount', displayName: 'Net Amount', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'taxAmount', displayName: 'Tax Amount', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'grossAmount', displayName: 'Gross Amount', type: 'TEXT', align: 'right', readOnly: true},
                ]}
                rows={computedTaxItems}
                updateRows={() => null}
                rowValidityCheckers={[]}
              />

              <Space h={20}/>

              <DataSheet
                caption={"Totals"}
                columns={[
                  {name: 'name', displayName: 'Name', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'netAmount', displayName: 'Net Amount', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'taxAmount', displayName: 'Tax Amount', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'grossAmount', displayName: 'Gross Amount', type: 'TEXT', align: 'right', readOnly: true},
                ]}
                rows={computedTotals}
                updateRows={() => null}
                rowValidityCheckers={[]}
              />
            </Flex>
          </Flex>

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <ActionButton
              defaultAction={
                {
                  label: "Save",
                  action: () => handleSave(),
                  leftSection: <IconDeviceFloppy size="1.5rem" stroke={1.5}/>,
                }
              }
              options={[
                {
                  label: <Flex dir="row" gap="xs">Save as <StatusBadge status="IN_PROGRESS"/></Flex>,
                  action: () => handleSave('IN_PROGRESS'),
                  leftSection: <IconAd2 size="1.5rem" stroke={1.5}/>,
                  disabled: extractionId === undefined || extraction?.status === 'IN_PROGRESS'
                },
                {
                  label: <Flex dir="row" gap="xs">Save as <StatusBadge status="COMPLETE"/></Flex>,
                  action: () => handleSave('COMPLETE'),
                  leftSection: <IconAd2 size="1.5rem" stroke={1.5}/>,
                  disabled: extractionId === undefined || extraction?.status === 'COMPLETE'
                },
              ]}
            />
            <Button variant="outline" leftSection={<IconCircleX/>} onClick={() => closeModal(false)} disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Modal>
});
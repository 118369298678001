import {Button, Fieldset, Flex, Modal, Switch, TextInput} from "@mantine/core";
import React, {FormEvent, useCallback, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {ApiAddress, ApiClient, ApiContact, UpsertContactRequest} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX, IconExclamationCircle} from "@tabler/icons-react";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";

interface Props {
  onSuccess?: (apiContact: ApiContact) => void
  onClose?: () => void
}

interface Contact {
  id: string
  organisationId: string
  name: string
  registrationNumber: string
  address: ApiAddress
  iban: string
  email: string
  phoneNumber: string
  supplier: boolean
  customer: boolean
  active: boolean
}

const mapApiContact = (apiContact?: ApiContact) => {
  return {
    id: apiContact ? apiContact.id : '',
    organisationId: apiContact ? apiContact.organisationId : '',
    name: apiContact ? apiContact.name : '',
    registrationNumber: apiContact ? apiContact.registrationNumber : '',
    address: apiContact ? apiContact.address : {} as ApiAddress,
    iban: apiContact ? apiContact.iban : '',
    email: apiContact ? apiContact.email : '',
    phoneNumber: apiContact ? apiContact.phoneNumber : '',
    supplier: apiContact ? apiContact.supplier : false,
    customer: apiContact ? apiContact.customer : false,
    active: apiContact ? apiContact.active : true,
  } as Contact;
}

export const ContactModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [contact, setContact] = useState<Contact>(mapApiContact(undefined));

  const openModal = useCallback((apiContact?: ApiContact) => {
    setContact(mapApiContact(apiContact));
    open();
  }, [open]);

  const closeModal = useCallback(() => {
    setContact(mapApiContact(undefined))
    close();
    onClose?.();
  }, [setContact, close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    const request = {
      name: contact.name,
      registrationNumber: contact.registrationNumber,
      address: contact.address,
      iban: contact.iban,
      email: contact.email,
      phoneNumber: contact.phoneNumber,
      supplier: contact.supplier,
      customer: contact.customer,
      active: contact.active
    } as UpsertContactRequest;
    (contact.id
        ? ApiClient.updateContact(organisationId ?? '', contact.id, request)
        : ApiClient.createContact(organisationId ?? '', request)
    )
      .then((resp) => {
        onSuccess?.(resp.data);
        closeModal();
        notifySavedChanges();
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  return <>
    <Modal opened={opened}
           onClose={closeModal}
           title="Contact"
           closeOnClickOutside={false}
           transitionProps={{duration: 100}}
           size="auto"
    >
      <Flex align="center" direction="column" gap="md">
        <form style={{width: "100%"}} onSubmit={handleSave}>

          <Flex direction="row">
            <Flex direction="column">

              <Fieldset legend="General information" disabled={loading}>
                <TextInput placeholder="Some company LTD"
                           label="Name"
                           value={contact?.name}
                           style={{width: '400px'}}
                           onChange={(e) => setContact({...contact, name: e.target.value})}
                />

                <TextInput placeholder="e.g. OIB"
                           label="Registration Number"
                           value={contact?.registrationNumber}
                           style={{width: '400px'}}
                           onChange={(e) => setContact({...contact, registrationNumber: e.target.value})}
                />

                <Flex direction="row" justify="space-around" style={{paddingTop: '10px'}}>
                  <Switch size={"sm"}
                          labelPosition="left"
                          label="Supplier"
                          checked={contact.supplier}
                          onChange={e => setContact({...contact, supplier: e.target.checked})}
                  />
                  <Switch size={"sm"}
                          labelPosition="left"
                          label="Customer"
                          checked={contact.customer}
                          onChange={e => setContact({...contact, customer: e.target.checked})}
                  />
                </Flex>
              </Fieldset>

              <Fieldset legend="Contanct information" disabled={loading}>
                <TextInput placeholder="email@company.com"
                           label="Email"
                           value={contact?.email}
                           style={{width: '400px'}}
                           onChange={(e) => setContact({...contact, email: e.target.value})}
                />
                <TextInput placeholder="+385 01 234 5678"
                           label="Phone number"
                           value={contact?.phoneNumber}
                           style={{width: '400px'}}
                           onChange={(e) => setContact({...contact, phoneNumber: e.target.value})}
                />
              </Fieldset>

              <Fieldset legend="Financial information" disabled={loading}>
                <TextInput placeholder="HR3423600009567811941"
                           label="IBAN"
                           value={contact?.iban}
                           style={{width: '400px'}}
                           onChange={(e) => setContact({...contact, iban: e.target.value})}
                />
              </Fieldset>

            </Flex>

            <Fieldset legend="Address" disabled={loading}>
              <TextInput placeholder="Address First Line"
                         label="Address Line 1"
                         value={contact?.address?.line1}
                         style={{width: '400px'}}
                         onChange={(e) => setContact({
                           ...contact,
                           address: {...contact.address, line1: e.target.value}
                         })}
              />
              <TextInput placeholder="Address Second Line"
                         label="Address Line 2"
                         value={contact?.address?.line2}
                         style={{width: '400px'}}
                         onChange={(e) => setContact({
                           ...contact,
                           address: {...contact.address, line2: e.target.value}
                         })}
              />
              <TextInput placeholder="10000"
                         label="Postal code"
                         value={contact?.address?.postalCode}
                         style={{width: '400px'}}
                         onChange={(e) => setContact({
                           ...contact,
                           address: {...contact.address, postalCode: e.target.value}
                         })}
              />
              <TextInput placeholder="Zagreb"
                         label="City"
                         value={contact?.address?.city}
                         style={{width: '400px'}}
                         onChange={(e) => setContact({
                           ...contact,
                           address: {...contact.address, city: e.target.value}
                         })}
              />
              <TextInput placeholder="Croatia"
                         label="Country"
                         value={contact?.address?.country}
                         style={{width: '400px'}}
                         onChange={(e) => setContact({
                           ...contact,
                           address: {...contact.address, country: e.target.value}
                         })}
              />
            </Fieldset>

          </Flex>


          <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
            <Switch size={"sm"}
                    labelPosition="left"
                    label="Active"
                    style={{paddingTop: '5px'}}
                    checked={contact.active}
                    onChange={e => setContact({...contact, active: e.target.checked})}
            />
          </Flex>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>} loading={loading}>
              Save
            </Button>
            <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  </>;
});
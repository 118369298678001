import {deleteJson, getJson, getText, postFile, postJson} from "./httpUtils";
import {Currency} from "../currency";

// Auth
export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponse {
  accessToken: string
}

type UserRole = "USER" | "ADMIN";

// Users
export interface ApiUser {
  id: string
  organisationId: string
  displayName: string
  email: string
  active: boolean
  roles: UserRole[]
  createdAt: Date
  updatedAt: Date
}


// Organisation
export interface ApiOrganisation {
  id: string
  name: string
  settings: ApiOrganisationSetting
}

export interface ApiOrganisationSetting {
  registrationNumber?: string
  baseCurrency?: string
  address?: ApiAddress
  email?: string
  phoneNumber?: string
}

export interface ApiAddress {
  line1?: string
  line2?: string
  postalCode?: string
  city?: string
  country?: string
}

// Taxes
export interface ApiTax {
  id: string
  organisationId: string
  name: string
  rate: number
  active: boolean
  createTime: Date
  updateTime: Date
}

export interface UpsertTaxRequest {
  name: string
  rate: number
  active: boolean
}

// Units
export interface ApiUnit {
  id: string
  organisationId: string
  name: string
  shortName: string
  active: boolean
}

export interface UpsertUnitRequest {
  name: string
  shortName: string
  active: boolean
}

// Items
export interface ApiItem {
  id: string
  categoryId: string
  organisationId: string
  name: string
  unitId: string
  sold: boolean
  stocked: boolean
  produced: boolean
  active: boolean
  bomItems: ApiBomItem[]
}

export interface UpsertItemRequest {
  name: string
  categoryId: string
  unitId: string
  sold: boolean
  stocked: boolean
  produced: boolean
  active: boolean
  bomItems: UpsertBomItemRequest[]
}

// Item Categories
export interface ApiItemCategory {
  id: string
  organisationId: string
  name: string
  active: boolean
}

export interface UpsertItemCategoryRequest {
  name: string
  active: boolean
}

// Bom Items
export interface ApiBomItem {
  id: string
  organisationId: string
  productId: string
  partId: string
  quantity: number
}

export interface UpsertBomItemRequest {
  partId: string
  quantity: number
}

// Contacts
export interface ApiContact {
  id: string
  organisationId: string
  name: string | null
  registrationNumber: string | null
  address: string | null
  iban: string | null
  email: string | null
  phoneNumber: string | null
  supplier: boolean | null
  customer: boolean | null
  active: boolean | null
  createTime: Date
  updateTime: Date
  actorId: string
}

export interface UpsertContactRequest {
  name: string | null
  registrationNumber: string | null
  address: string | null
  iban: string | null
  email: string | null
  phoneNumber: string | null
  supplier: boolean | null
  customer: boolean | null
  active: boolean | null
}

// Incoming invoices
export interface ApiIncomingInvoice {
  id: string
  organisationId: string
  supplierId: string | null
  invoiceNumber: string | null
  currency: Currency | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  bookingDate: string | null
  bookingStatus: BookingStatus
  lineItems: ApiIncomingInvoiceLineItem[]
  taxItems: ApiIncomingInvoiceTaxItem[]
}

export interface ApiIncomingInvoiceLineItem {
  id: string
  organisationId: string
  incomingInvoiceId: string
  itemId: string | undefined
  taxId: string | undefined
  text: string | undefined
  unitPrice: number | undefined
  quantity: number | undefined
  netAmount: number | undefined
  taxAmount: number | undefined
  grossAmount: number | undefined
}

export interface ApiIncomingInvoiceTaxItem {
  id: string
  organisationId: string
  incomingInvoiceId: string
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

export interface ApiUpsertIncomingInvoiceRequest {
  supplierId: string | null
  invoiceNumber: string | null
  currency: string | null
  netAmount: string | null
  taxAmount: string | null
  grossAmount: string | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  lineItems: ApiUpsertIncomingInvoiceLineItemRequest[]
  taxItems: ApiUpsertIncomingInvoiceTaxItemRequest[]
  bookingDate: string | null
  bookingStatus: BookingStatus
}

export interface ApiUpsertIncomingInvoiceLineItemRequest {
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | undefined
  taxAmount: number | undefined
  grossAmount: number | undefined
}

export interface ApiUpsertIncomingInvoiceTaxItemRequest {
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

// Registers
export interface ApiRegister {
  id: string
  organisationId: string
  name: string
}

export interface ApiRegisterReport {
  id: string
  organisationId: string
  registerId: string | null
  date: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  bookingDate: string | null
  bookingStatus: BookingStatus
  lineItems: ApiRegisterReportLineItem[]
  taxItems: ApiRegisterReportTaxItem[]

  [key: string]: any
}

export interface ApiRegisterReportLineItem {
  id: string
  organisationId: string
  registerReportId: string
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export interface ApiRegisterReportTaxItem {
  id: string
  organisationId: string
  registerReportId: string
  taxId: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export interface ApiUpsertRegisterReportRequest {
  registerId: string | null
  date: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  lineItems: ApiUpsertRegisterReportLineItemRequest[]
  taxItems: ApiUpsertRegisterReportTaxItemRequest[]
  bookingDate: string | null
  bookingStatus: BookingStatus
}

export interface ApiUpsertRegisterReportLineItemRequest {
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

export interface ApiUpsertRegisterReportTaxItemRequest {
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

// Files
export interface ApiFile {
  id: string
  organisationId: string
  name: string
  type: string
  size: number
  status: FileStatus
  ocrResponse: ApiOcrResponse
  extractions: ApiExtraction[]
}

export interface ApiOcrResponse {
  pages: ApiPage[]
}

export interface ApiPage {
  boxes: ApiTextBox[]
  width: number
  height: number
}

export interface ApiTextBox {
  text: string
  box: ApiBox
}

export interface ApiBox {
  l: number
  t: number
  w: number
  h: number
}

export type DocumentType = 'INCOMING_INVOICE' | 'REGISTER_REPORT';
export type ExtractionStatus = 'CREATING' | 'IN_PROGRESS' | 'COMPLETE';
export type FileStatus = 'CREATING' | 'IN_PROGRESS' | 'EXTRACTED';
export type InvoiceStatus = 'CREATING' | 'DRAFT' | 'BOOKED' | 'PARTIAL';
export type BookingStatus = 'DRAFT' | 'BOOKED' | 'PARTIAL';
export type BookingType = 'PARTIAL' | 'FULL';
export type BalancePeriod = 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR';

export interface ApiExtraction {
  id: string
  organisationId: string
  fileId: string
  status: ExtractionStatus
  description: string | null
  documentId: string | null
  documentType: DocumentType
  body: ApiExtractionBody
}

export interface ApiExtractionBody {
  supplierId: string | null
  invoiceNumber: string | null
  netAmount: string | null
  taxAmount: string | null
  grossAmount: string | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  lineItems: ApiExtractionLineItem[]
  taxItems: ApiExtractionTaxItem[]
  discountInPercentage: boolean
  discountIncludedInAmounts: boolean
}

export interface ApiExtractionLineItem {
  // Directly extracted fields
  text: string
  quantity1: string
  quantity2: string
  quantity3: string
  unit1: string
  unit2: string
  discount: string
  tax: string
  netAmount: string // qty * price * disc.
  grossAmount: string // qty * price * disc * (1 + tax)
  // Mapped fields
  mappedItemId: string
  mappedQuantity: string
  mappedUnit: string
  mappedUnitPrice: string
  mappedNetAmount: string
  mappedGrossAmount: string

  [key: string]: string
}

export interface ApiExtractionTaxItem {
  rate: string
  netAmount: string
  taxAmount: string
  grossAmount: string

  [key: string]: string
}

export interface ApiUpsertExtractionRequest {
  fileId: string
  description: string | null
  documentType: DocumentType
  extractionBody: ApiExtractionBody
}

export interface ApiBalances {
  items: Record<string, ApiBalance[]>
  balancePeriod: BalancePeriod
}

export interface ApiBalance {
  organisationId: string
  itemId: string
  date: string
  amount: number
  quantity: number
}

export interface ApiTransaction {
  id: string
  type: DocumentType
  organisationId: string
  documentId: string
  itemId: string
  amount: number
  quantity: number
  bookingDate: string
  bookingType: BookingType
}


export class ApiClient {
  // Auth
  static doLogin(email: String, password: String) {
    const request = {email, password} as LoginRequest;
    return postJson<LoginRequest, LoginResponse>('/auth/login', request);
  }

  static doRefresh() {
    return getJson<LoginResponse>('/auth/refresh');
  }

  static doLogout() {
    return deleteJson<void>('/auth/login');
  }

  // Users
  static getCurrentUser() {
    return getJson<ApiUser>(`/users/current`);
  }

  static getUsersByOrganisation(organisationId: string) {
    return getJson<ApiUser[]>(`/users/by-organisation/${organisationId}`);
  }

  // Organisation
  static getOrganisations() {
    return getJson<ApiOrganisation[]>(`/organisations`);
  }

  static getOrganisation(organisationId: string) {
    return getJson<ApiOrganisation>(`/organisations/${organisationId}`);
  }

  static updateOrganisationSettings(organisationId: string, request: ApiOrganisationSetting) {
    return postJson<ApiOrganisationSetting, ApiOrganisation>(`/organisations/${organisationId}/settings`, request);
  }

  // Taxes
  static getTaxes(organisationId: string) {
    return getJson<ApiTax[]>(`/organisations/${organisationId}/taxes`);
  }

  static createTax(organisationId: string, request: UpsertTaxRequest) {
    return postJson<UpsertTaxRequest, ApiTax>(`/organisations/${organisationId}/taxes`, request);
  }

  static updateTax(organisationId: string, taxId: string, request: UpsertTaxRequest) {
    return postJson<UpsertTaxRequest, ApiTax>(`/organisations/${organisationId}/taxes/${taxId}`, request);
  }

  // Units
  static getUnits(organisationId: string) {
    return getJson<ApiUnit[]>(`/organisations/${organisationId}/units`);
  }

  static createUnit(organisationId: string, request: UpsertUnitRequest) {
    return postJson<UpsertUnitRequest, ApiUnit>(`/organisations/${organisationId}/units`, request);
  }

  static updateUnit(organisationId: string, taxId: string, request: UpsertUnitRequest) {
    return postJson<UpsertUnitRequest, ApiUnit>(`/organisations/${organisationId}/units/${taxId}`, request);
  }

  // Items
  static getItems(organisationId: string) {
    return getJson<ApiItem[]>(`/organisations/${organisationId}/items`);
  }

  static createItem(organisationId: string, request: UpsertItemRequest) {
    return postJson<UpsertItemRequest, ApiItem>(`/organisations/${organisationId}/items`, request);
  }

  static updateItem(organisationId: string, itemId: string, request: UpsertItemRequest) {
    return postJson<UpsertItemRequest, ApiItem>(`/organisations/${organisationId}/items/${itemId}`, request);
  }

  // Item Categories
  static getItemCategories(organisationId: string) {
    return getJson<ApiItemCategory[]>(`/organisations/${organisationId}/categories`);
  }

  static createItemCategory(organisationId: string, request: UpsertItemCategoryRequest) {
    return postJson<UpsertItemCategoryRequest, ApiItemCategory>(`/organisations/${organisationId}/categories`, request);
  }

  static updateItemCategory(organisationId: string, categoryId: string, request: UpsertItemCategoryRequest) {
    return postJson<UpsertItemCategoryRequest, ApiItemCategory>(`/organisations/${organisationId}/categories/${categoryId}`, request);
  }

  // BOM Items
  static getBomItems(organisationId: string, itemId: string) {
    return getJson<ApiBomItem[]>(`/organisations/${organisationId}/items/${itemId}/bom-items`);
  }

  // Contacts
  static getContacts(organisationId: string) {
    return getJson<ApiContact[]>(`/organisations/${organisationId}/contacts`);
  }

  static createContact(organisationId: string, request: UpsertContactRequest) {
    return postJson<UpsertContactRequest, ApiContact>(`/organisations/${organisationId}/contacts`, request);
  }

  static updateContact(organisationId: string, partyId: string, request: UpsertContactRequest) {
    return postJson<UpsertContactRequest, ApiContact>(`/organisations/${organisationId}/contacts/${partyId}`, request);
  }

  // Incoming Invoices
  static getIncomingInvoices(organisationId: string) {
    return getJson<ApiIncomingInvoice[]>(`/organisations/${organisationId}/incoming-invoices`);
  }

  // Incoming Invoices
  static getIncomingInvoice(organisationId: string, incomingInvoiceId: string) {
    return getJson<ApiIncomingInvoice>(`/organisations/${organisationId}/incoming-invoices/${incomingInvoiceId}`);
  }

  static createIncomingInvoice(organisationId: string, request: ApiUpsertIncomingInvoiceRequest) {
    return postJson<ApiUpsertIncomingInvoiceRequest, ApiIncomingInvoice>(`/organisations/${organisationId}/incoming-invoices`, request);
  }

  static updateIncomingInvoice(organisationId: string, incomingInvoiceId: string, request: ApiUpsertIncomingInvoiceRequest) {
    return postJson<ApiUpsertIncomingInvoiceRequest, ApiIncomingInvoice>(`/organisations/${organisationId}/incoming-invoices/${incomingInvoiceId}`, request);
  }

  // Registers
  static getRegisters(organisationId: string) {
    return getJson<ApiRegister[]>(`/organisations/${organisationId}/registers`);
  }

  static getRegisterReports(organisationId: string) {
    return getJson<ApiRegisterReport[]>(`/organisations/${organisationId}/register-reports`);
  }

  static getRegisterReport(organisationId: string, registerReportId: string) {
    return getJson<ApiRegisterReport>(`/organisations/${organisationId}/register-reports/${registerReportId}`);
  }

  static createRegisterReport(organisationId: string, request: ApiUpsertRegisterReportRequest) {
    return postJson<ApiUpsertRegisterReportRequest, ApiRegisterReport>(`/organisations/${organisationId}/register-reports`, request);
  }

  static updateRegisterReport(organisationId: string, registerReportId: string, request: ApiUpsertRegisterReportRequest) {
    return postJson<ApiUpsertRegisterReportRequest, ApiRegisterReport>(`/organisations/${organisationId}/register-reports/${registerReportId}`, request);
  }


  // Files
  static getFile(organisationId: string, fileId: string) {
    return getJson<ApiFile>(`/organisations/${organisationId}/files/${fileId}`);
  }

  static getFiles(organisationId: string) {
    return getJson<ApiFile[]>(`/organisations/${organisationId}/files`);
  }

  static getFilePageImage(organisationId: string, fileId: string, pageNum: number) {
    return getText<string>(`/organisations/${organisationId}/files/${fileId}/pages/${pageNum}`);
  }

  static uploadFile(organisationId: string, file: File) {
    return postFile<ApiFile>(`/organisations/${organisationId}/files`, file);
  }

  static updateFileStatus(organisationId: string, fileId: string, status: FileStatus) {
    return postJson(`/organisations/${organisationId}/files/${fileId}/status/${status}`);
  }

  // Extractions
  static getExtractions(organisationId: string) {
    return getJson<ApiExtraction[]>(`/organisations/${organisationId}/extractions`);
  }

  static getExtraction(organisationId: string, extractionId: string) {
    return getJson<ApiExtraction>(`/organisations/${organisationId}/extractions/${extractionId}`);
  }

  static createExtraction(organisationId: string, request: ApiUpsertExtractionRequest) {
    return postJson<ApiUpsertExtractionRequest, ApiExtraction>(`/organisations/${organisationId}/extractions`, request);
  }

  static updateExtraction(organisationId: string, extractionId: string, request: ApiUpsertExtractionRequest) {
    return postJson<ApiUpsertExtractionRequest, ApiExtraction>(`/organisations/${organisationId}/extractions/${extractionId}`, request);
  }

  static updateExtractionStatus(organisationId: string, extractionId: string, status: ExtractionStatus) {
    return postJson<void, void>(`/organisations/${organisationId}/extractions/${extractionId}/status/${status}`);
  }

  static createInvoiceFromExtraction(organisationId: string, extractionId: string) {
    return postJson<void, void>(`/organisations/${organisationId}/extractions/${extractionId}/create-invoice`);
  }

  static unlinkExtractionDocument(organisationId: string, extractionId: string) {
    return postJson<void, void>(`/organisations/${organisationId}/extractions/${extractionId}/unlink-document`);
  }

  // Balances
  static getBalances(organisationId: string, startDate: string | null, endDate: string, bookingTypes: BookingType[]) {
    const requestVars =
      `bookingTypes=${bookingTypes.join(',')}` +
      `&endDate=${endDate}` +
      (startDate !== null
        ? `&startDate=${startDate}`
        : `&startDate=${endDate}`);
    return getJson<ApiBalances>(`/organisations/${organisationId}/balances?${requestVars}`);
  }

  // Transactions
  static getTransactions(organisationId: string, startDate: string | null, endDate: string, bookingTypes: BookingType[]) {
    const requestVars =
      `bookingTypes=${bookingTypes.join(',')}` +
      `&endDate=${endDate}` +
      (startDate !== null
        ? `&startDate=${startDate}`
        : `&startDate=${endDate}`);
    return getJson<ApiTransaction[]>(`/organisations/${organisationId}/transactions?${requestVars}`);
  }

}
import React from "react";
import {Badge, Chip, Flex, Text} from "@mantine/core";

interface Props {
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const PageControl = ({pageCount, currentPage, onPageChange}: Props) => {
  return <Flex direction="row" justify="right" align="center">
    <Text style={{paddingRight: '0.5rem'}}>Pages: </Text>
    {[...Array(pageCount)].map((_, idx) => (
      <Badge key={idx}
             onClick={() => onPageChange(idx)}
             size="md"
             variant={idx === currentPage ? 'filled' : 'default'}
             style={{cursor: 'pointer'}}
      >
        {idx + 1}
      </Badge>
    ))}
  </Flex>;
}
import {ApiExtractionLineItem} from "../../../../../utils/http/apiClient";
import {isNumber} from "util";
import {roundAmount, roundToX} from "../../../../../utils/objectUtils";
import {round} from "@mantine/core/lib/components/ColorPicker/converters/parsers";

export interface UnitQuantity {
  quantity: string
  unit: string
}

export function mapInvoiceItems(lineItems: ApiExtractionLineItem[],
                                discountInPercentage?: boolean,
                                discountIncludedInAmounts?: boolean): ApiExtractionLineItem[] {
  return lineItems.map(lineItem => {
    const li = {...lineItem};

    li.mappedUnit = li.unit1 ? li.unit1 : li.unit2;

    const qty1 = getNumberValueOrDefault(li.quantity1, 1);
    const qty2 = getNumberValueOrDefault(li.quantity2, 1);
    const qty3 = getNumberValueOrDefault(li.quantity3, 1);

    const mappedQty = li.unit1 && li.unit1 !== li.unit2
      ? roundToX(qty1 * qty2 * qty3, 4)
      : roundToX(qty2 * qty3, 4);
    li.mappedQuantity = mappedQty.toString();

    const tax = getNumberValueOrDefault(li.tax, Number.NaN);
    const netAmount = getNumberValueOrDefault(li.netAmount, Number.NaN);
    const grossAmount = getNumberValueOrDefault(li.grossAmount, Number.NaN);
    const discount = !discountIncludedInAmounts ? getNumberValueOrDefault(li.discount, 0) : 0;

    if (!Number.isNaN(netAmount)) {
      const mappedNetAmount = discountInPercentage
        ? roundAmount(netAmount / (100 + discount) * 100)
        : roundAmount(netAmount - discount);
      const mappedGrossAmount = roundAmount(mappedNetAmount * (100 + tax) / 100);
      const mappedUnitPrice = roundToX(mappedNetAmount / mappedQty, 4);
      li.mappedNetAmount = mappedNetAmount.toString();
      li.mappedGrossAmount = mappedGrossAmount.toString();
      li.mappedUnitPrice = mappedUnitPrice.toString();
    } else {
      const mappedGrossAmount = discountInPercentage
        ? roundAmount(grossAmount * (100 + discount) / 100)
        : roundAmount(grossAmount - (discount * (100 + tax) / 100));
      const mappedNetAmount = roundAmount(mappedGrossAmount / (100 + tax) * 100);
      const mappedUnitPrice = roundToX(mappedNetAmount / mappedQty, 4);

      li.mappedNetAmount = mappedNetAmount.toString();
      li.mappedGrossAmount = mappedGrossAmount.toString();
      li.mappedUnitPrice = mappedUnitPrice.toString();
    }

    return li;
  });
}

function getNumberValueOrDefault(text: string, defaultValue: number): number {
  if (!text || text.trim() === "" || !isNumeric(text)) {
    return defaultValue;
  } else {
    return Number(cleanNumber(text)) ?? defaultValue;
  }
}

export const deriveUnitAndQuantity = (lineText: string) => {
  const qtyPattern = /(?<qty>[0-9]+([.,][0-9]+)?)/;
  const suffixPattern = /([^A-Z]+|$)/;
  const patterns = [
    {
      regex: new RegExp(qtyPattern.source + /\s*G/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*GR/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*GRAMA?/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*KG/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 1
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*L/.source + suffixPattern.source),
      unit: 'L',
      multiplier: 1
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*ML/.source + suffixPattern.source),
      unit: 'L',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\sKOM/.source + suffixPattern.source),
      unit: 'KOM',
      multiplier: 1
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*BOCA/.source + suffixPattern.source),
      unit: 'KOM',
      multiplier: 1
    },
  ];

  for (const pattern of patterns) {
    const match = pattern.regex.exec(lineText?.toUpperCase() ?? '');
    const group = match?.groups?.['qty'];
    if (group) {
      return {
        quantity: (Number(cleanNumber(group)) * pattern.multiplier).toString(),
        unit: pattern.unit
      }
    }
  }
  return {quantity: null, unit: null}
}

export const cleanNumber = (text: string) => {
  text = (text ?? '').toString().replaceAll(',', '.');
  const lastDot = text?.indexOf('.');
  if (lastDot >= 0) {
    const leftPart = text.substring(0, lastDot).replaceAll('.', '');
    const rightPart = text.substring(lastDot);
    text = leftPart + (rightPart.length > 1 ? rightPart : '');
  }
  return text?.trim();
}

export const isNumeric = (text: string) => {
  text = cleanNumber(text);
  return !text || isFinite(Number(text));
}

export const isUnit = (text: string) => {
  const units = new Set(['', 'KO', 'KOM', 'KG', 'L']);
  return units.has(text?.toUpperCase() ?? '');
}

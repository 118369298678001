import React, {MouseEvent, useCallback, useEffect, useRef, useState} from "react";
import {
  Box,
  BoxGroup,
  boxGroupContains,
  drawBoxes,
  groupIntoLines,
  isBoxBeingSelected,
  mapToBoxGroup,
  mergeBoxGroups,
  Point,
  removeBox
} from "./canvas";
import {FileCanvasContextMenu} from "./FileCanvasContextMenu";

type Mode = 'SELECTION' | 'MERGE' | 'REMOVE_GROUP' | 'TOGGLE_BOX_IN_GROUP';

const BORDER_COLORS = {
  'SELECTION': 'black',
  'MERGE': 'blue',
  'REMOVE_GROUP': 'red',
  'TOGGLE_BOX_IN_GROUP': 'purple',
};

interface Props {
  image: HTMLImageElement | null
  boxes: Box[],
  width: number
  height: number
}

export const FileCanvas = ({image, boxes, width, height}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const contextMenu = useRef<any>();

  const [mode, setMode] = useState<Mode>('SELECTION');
  const [zoom, setZoom] = useState(1);
  const [leftOffset, setLeftOffset] = useState(0);
  const [topOffset, setTopOffset] = useState(0);

  const [hoveredBox, setHoveredBox] = useState<Box | null>();
  const [hoveredBoxGroup, setHoveredBoxGroup] = useState<BoxGroup | null>();

  const [mousePosition, setMousePosition] = useState<Point | null>();
  const [dragStartPoint, setDragStartPoint] = useState<Point | null>();
  const [selectStartPoint, setSelectStartPoint] = useState<Point | null>();

  const [selectedBoxGroups, setSelectedBoxGroups] = useState<BoxGroup[]>([]);

  const [selectionBox, setSelectionBox] = useState<Box | null>();

  const [selectingBoxes, setSelectingBoxes] = useState<Box[]>([]);
  const [nonSelectingBoxes, setNonSelectingBoxes] = useState<Box[]>([]);

  const [selectingBoxGroups, setSelectingBoxGroups] = useState<BoxGroup[]>([]);
  const [nonSelectingBoxGroups, setNonSelectingBoxGroups] = useState<BoxGroup[]>([]);

  const [cursor, setCursor] = useState('pointer');

  const draw = useCallback(() => {
    if (!canvasRef || !image) {
      return;
    }
    const context = canvasRef.current?.getContext('2d');
    if (!context) {
      return;
    }

    context.fillStyle = "gray";
    context.fillRect(0, 0, image.width, image.height);
    context.drawImage(image, leftOffset, topOffset, image.width * zoom, image.height * zoom);

    if (selectionBox) {
      drawBoxes(context, [selectionBox], "blue", 1, leftOffset, topOffset, zoom);
    }

    if (mode !== 'MERGE') {
      drawBoxes(context, selectingBoxes, "red", 2, leftOffset, topOffset, zoom);
      drawBoxes(context, nonSelectingBoxes, "gray", 1, leftOffset, topOffset, zoom);
    }

    drawBoxes(context, selectingBoxGroups, "red", 2, leftOffset, topOffset, zoom);
    drawBoxes(context, nonSelectingBoxGroups, "blue", 1, leftOffset, topOffset, zoom);
  }, [canvasRef, image, boxes, zoom, leftOffset, topOffset,
    selectionBox, selectingBoxes, nonSelectingBoxes, selectedBoxGroups, nonSelectingBoxGroups]);


  useEffect(() => {
    draw();
  }, [draw]);

  const clearSelection = () => {
    setDragStartPoint(null);
    setSelectStartPoint(null);
    setSelectionBox(null);
    setSelectingBoxes([]);
    setNonSelectingBoxes(boxes);
    setSelectingBoxGroups([]);
    setNonSelectingBoxGroups([]);
    setSelectedBoxGroups([]);
    setHoveredBox(null);
    setHoveredBoxGroup(null);
  };

  const onCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
    event.preventDefault();

    if (event.button !== 0) {
      return; // Ignore right click
    }

    if (event.shiftKey || hoveredBox !== null) {
      setDragStartPoint(null);
      setSelectStartPoint(mousePosition);
      setCursor('crosshair');
    } else {
      setDragStartPoint({x: event.clientX, y: event.clientY} as Point);
      setSelectStartPoint(null);
      setCursor('grabbing');
    }
  }

  const onCanvasMouseUp = (event: MouseEvent<HTMLCanvasElement>) => {
    if (event.button !== 0) {
      return; // Ignore right click
    }

    let updatedSelectedBoxGroups: null | BoxGroup[] = null

    if (mode === 'REMOVE_GROUP') {
      updatedSelectedBoxGroups = nonSelectingBoxGroups;
    } else if (mode === 'MERGE' && event.shiftKey) {
      const mergedGroup = selectingBoxGroups.length > 0
        ? mergeBoxGroups(selectingBoxGroups)
        : null;
      const selectedBoxGroups = nonSelectingBoxGroups.concat(mergedGroup !== null ? [mergedGroup] : []);
      updatedSelectedBoxGroups = selectedBoxGroups.sort((a, b) => a.t - b.t);
    } else if (selectStartPoint) {
      const boxGroupsToConcat = selectingBoxes
        .filter(box => selectedBoxGroups.filter(bg => boxGroupContains(bg, box)).length == 0)
        .map(mapToBoxGroup);
      const concatenatedSelectedBoxGroups = selectedBoxGroups.concat(boxGroupsToConcat).sort((a, b) => a.t - b.t);
      updatedSelectedBoxGroups = groupIntoLines(concatenatedSelectedBoxGroups);
    }

    if (updatedSelectedBoxGroups !== null) {
      setSelectedBoxGroups(updatedSelectedBoxGroups);
      copyBoxesIntoClipboard(updatedSelectedBoxGroups);
    }

    setDragStartPoint(null);
    setSelectStartPoint(null);
    setCursor('pointer');
  }

  const copyBoxesIntoClipboard = (boxGroups: BoxGroup[]) => {
    const copied = boxGroups.map(bg => bg.boxes.map(b => b.text).join(" ")).join('\n');
    navigator?.clipboard?.writeText(copied);
  }

  const onCanvasDoubleClick = (event: MouseEvent<HTMLCanvasElement>) => {
    if (mode === 'SELECTION') {
      clearSelection();
    }
    setCursor('grab');
  }

  const onCanvasMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    let bound = canvasRef.current?.getBoundingClientRect();
    if (bound && canvasRef.current) {
      const x = (event.clientX - bound.left - canvasRef.current?.clientLeft) / zoom;
      const y = (event.clientY - bound.top - canvasRef.current?.clientTop) / zoom;
      setMousePosition({x, y} as Point);
    }

    if (dragStartPoint) {
      const deltaX = event.clientX - dragStartPoint.x;
      const deltaY = event.clientY - dragStartPoint.y;
      setLeftOffset(leftOffset + deltaX);
      setTopOffset(topOffset + deltaY);
      setDragStartPoint({x: event.clientX, y: event.clientY} as Point);
    }

    // Keep track of hovering
    const selectionBox = selectStartPoint && mousePosition
      ? {
        l: Math.min(selectStartPoint.x, mousePosition.x) - leftOffset / zoom,
        t: Math.min(selectStartPoint.y, mousePosition.y) - topOffset / zoom,
        w: Math.abs(selectStartPoint.x - mousePosition.x),
        h: Math.abs(selectStartPoint.y - mousePosition.y)
      } as Box
      : null;

    const mPoint = mousePosition
      ? {
        x: mousePosition.x - leftOffset / zoom,
        y: mousePosition.y - topOffset / zoom
      } as Point
      : null;

    const selectingBoxes = boxes.filter(b => isBoxBeingSelected(b, mPoint, selectionBox));
    const nonSelectingBoxes = boxes.filter(b => !isBoxBeingSelected(b, mPoint, selectionBox));
    const selectingBoxGroups = selectedBoxGroups.filter(bg => isBoxBeingSelected(bg, mPoint, selectionBox));
    const nonSelectingBoxGroups = selectedBoxGroups.filter(bg => !isBoxBeingSelected(bg, mPoint, selectionBox));

    setSelectionBox(selectionBox);
    setSelectingBoxes(selectingBoxes);
    setNonSelectingBoxes(nonSelectingBoxes);
    setSelectingBoxGroups(selectingBoxGroups);
    setNonSelectingBoxGroups(nonSelectingBoxGroups);

    const hoveredBox = selectingBoxes.length === 1 ? selectingBoxes[0] : null;
    const hoveredBoxGroup = selectingBoxGroups.length === 1 ? selectingBoxGroups[0] : null
    setHoveredBox(hoveredBox);
    setHoveredBoxGroup(hoveredBoxGroup);

    if (hoveredBox !== null) {
      setCursor('crosshair');
    } else {
      setCursor('grab');
    }
  }


  const wheelHandler = useCallback((event: WheelEvent) => {
    if (event.shiftKey) {
      event.preventDefault();
      if (event.deltaY < 0) {
        setZoom(zoom + 0.1);
      } else {
        setZoom(zoom - 0.1);
      }
    }
  }, [image, zoom, mousePosition, selectStartPoint]);

  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    if (event.shiftKey) {
      setCursor('crosshair');
    }
  }, [setCursor]);

  const keyUpHandler = useCallback((event: KeyboardEvent) => {
    setCursor('pointer');
  }, [setCursor]);

  const pasteHandler = useCallback((event: Event) => {
    clearSelection();
    setMode('SELECTION');
  }, [clearSelection, setMode]);

  useEffect(() => {
    canvasRef?.current?.addEventListener('wheel', wheelHandler, {passive: false});
    window.addEventListener('keydown', keyDownHandler, false);
    window.addEventListener('keyup', keyUpHandler, false);
    window.addEventListener('paste', pasteHandler, false);
    return () => {
      canvasRef?.current?.removeEventListener('keydown', keyDownHandler, false);
      window.removeEventListener('keydown', keyDownHandler, false);
      window.removeEventListener('keyup', keyUpHandler, false);
      window.removeEventListener('paste', pasteHandler, false);
    }
  }, [canvasRef, wheelHandler, keyDownHandler, keyUpHandler]);


  return <>
    <canvas ref={canvasRef}
            width={width}
            height={height}
            onMouseMove={onCanvasMouseMove}
            onMouseDown={onCanvasMouseDown}
            onMouseUp={onCanvasMouseUp}
            onDoubleClick={onCanvasDoubleClick}
            onContextMenu={e => {
              e.preventDefault();
              contextMenu.current.openMenu(e.clientX, e.clientY, hoveredBox, hoveredBoxGroup);
            }}
            tabIndex={1}
            style={{
              border: '2px solid ' + BORDER_COLORS[mode],
              width: `${width}px`,
              height: `${height}px`,
              cursor: cursor,
            }}
    />

    <FileCanvasContextMenu ref={contextMenu} onSuccess={({selectedMenuItem, hoveredBox, hoveredBoxGroup}) => {
      if (selectedMenuItem === 'GROUP_INTO_LINES') {
        setSelectedBoxGroups(groupIntoLines(selectedBoxGroups));
      } else if (selectedMenuItem === 'REMOVE_BOX' && hoveredBox) {
        setSelectedBoxGroups(selectedBoxGroups.map(bg => removeBox(bg, hoveredBox)).filter(bg => bg.boxes.length > 0));
      } else if (selectedMenuItem === 'REMOVE_BOX_GROUP' && hoveredBoxGroup) {
        setSelectedBoxGroups(selectedBoxGroups.filter(bg => bg !== hoveredBoxGroup));
      } else if (selectedMenuItem === 'MODE_SELECTION') {
        setMode('SELECTION');
        setSelectingBoxes([]);
      } else if (selectedMenuItem === 'MODE_REMOVE_GROUP') {
        setMode('REMOVE_GROUP');
      } else if (selectedMenuItem === 'MODE_MERGE') {
        setMode('MERGE');
      } else if (selectedMenuItem === 'CLEAR_SELECTION') {
        clearSelection();
      }
    }}/>
  </>
}